<template>
  <div class="container-transport">
    <v-container class="card-hx mb-8" v-show="active == 0" grid-list-xs>
      <v-row>
        <v-col>
          <img
            src="@/assets/images/driver-2.png"
            height="auto"
            width="100%"
            alt="transport"
          />
          <h3 class="header-transport">DRIVER BALITRIPCENTER</h3>

        </v-col>

      </v-row>
      <center><br>
      <v-row class="body" style="text-align: center"
        >{{ $t('pengemudi-kami-akan-membantu-kamu-memanfaatkan') }} </v-row>
      </center>
    </v-container>
    <v-container class="card-hx mb-8" v-show="active == 1" grid-list-xs>
      <v-row>
        <v-col>
          <img
            src="@/assets/images/bali-feature.png"
            height="auto"
            width="100%"
            alt="transport"
          />
          <h3 class="header-transport">TRAVEL BALITRIPCENTER</h3>
        </v-col>
      </v-row>
      <center><br>
      <v-row class="body"
        >{{ $t('kami-akan-memberi-kamu-pilihan-untuk') }}.</v-row
      >
      </center>
    </v-container>

    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs6>
          <center>
            <div  @click="active = 0" :class="selected0">{{ $t('driver-balitripcenter') }}</div>
            <div v-show="active == 0" class="selected-bg"></div>            
          </center>

        </v-flex>
        <v-flex xs6>
          <center >
            <div @click="active = 1" :class="selected1">{{ $t('travel-balitripcenter') }}</div>
            <div v-show="active == 1" class="selected-bg"></div>            
          </center>

        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
    }
  },
  computed: {
    selected0: function() {
        return this.active == 0 ? 'menu active' : 'menu';
    },
    selected1: function() {
        return this.active == 1 ? 'menu active' : 'menu';
    }
  }
};
</script>


<style scoped>
.header {
  font-weight: bold;
  font-size: 14px;
}
.hint {
  font-size: 11px;
  color: gray;
}
.body {
  font-size: 16px;
  color:#000;
}

.card-hx {
  border-radius: 14px;
  padding:20px;
  background-color: #F4F7F9;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.container-transport {
  background-color: #F3FEFF;
}

.header-transport {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}

.menu {
  font-weight: 600;
  color: gray;
}
.active {
  font-weight: 600;
  color: black;
}

.selected-bg {
  height: 8px;
  width: 40px;
  margin-top: 5px;
  background-color: #FD6301;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.h-line {
  height:4px;
  width: 100px;
  margin: 10px 0;
  background-color: #FD6301;
}

</style>