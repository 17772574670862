<template>
  <div>
    <!-- Tabs to switch between Photo and Video Galleries -->
    <v-tabs v-model="activeTab" background-color="primary" dark>
      <v-tab>Photo</v-tab>
      <v-tab>Video</v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeTab">
      <!-- Photo Gallery Tab -->
      <v-tab-item>
        <div v-if="photoPosts && photoPosts.length" v-show="!loadingPhotos">
          <v-container pa-2 fluid grid-list-md>
            <v-layout row wrap>
              <v-flex
                v-for="(photo, i) in photoPosts"
                :key="i"
                xs6 sm3
              >
                <v-card class="black--text" @click="openPhotoModal(i)">
                  <v-img
                    :src="photo.thumbnail"
                    :alt="photo.thumbnail"
                    height="150px"
                    width="100%"
                  ></v-img>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </div>

        <!-- Photo Modal Dialog with Carousel -->
        <v-dialog v-model="photoModalOpen" fullscreen overlay-color="black">
          <v-card class="d-flex flex-column align-center justify-center" style="background-color: black !important;">
            <!-- Close Button -->
            <v-btn
              class="close-button"
              icon
              color="white"
              @click="photoModalOpen = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-carousel
              v-model="photoIndex"
              height="100%"
              hide-delimiters
              show-arrows
              class="fill-height"
            >
              <v-carousel-item
                v-for="(photo, i) in photoPosts"
                :key="i"
              >
                <v-img :src="photo.thumbnail" class="fill-height"></v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-dialog>
      </v-tab-item>

      <!-- Video Visitor Tab -->
      <v-tab-item>
        <div v-if="videoPosts && videoPosts.length" v-show="!loadingVideos">
          <v-container pa-2 fluid grid-list-md>
            <v-layout row wrap>
              <v-flex
                v-for="(video, i) in videoPosts"
                :key="i"
                xs6 sm3
              >
                <div class="black--text position-relative" @click="openVideoModal(i)">
                  <v-img
                    :src="`${baseUrl}/${video.thumbnail}`"
                    :alt="video.title"
                    height="150px"
                    width="100%"
                  ></v-img>
                  <!-- Overlay Play Icon -->
                  <img
                    src="@/assets/images/play-video.svg"
                    alt="Play Video"
                    class="play-icon"
                  />
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </div>

        <!-- Video Modal -->
        <div
          v-if="videoModalOpen"
          class="video-modal"
          @touchstart="startTouch"
          @touchmove="onSwipe"
          @touchend="endTouch"
        >
          <!-- Close Button -->
          <v-btn
            icon
            class="close-btn"
            @click="closeVideoModal"
          >
            <v-icon style="font-size: 32px">mdi-close</v-icon>
          </v-btn>
          <video
            controls
            autoplay
            class="video-player"
            ref="videoPlayer"
            :src="getVideoPath(videoIndex)"
          ></video>
        </div>
      </v-tab-item>
    </v-tabs-items>

    <!-- Global Loading Spinner (shows if either photos or videos are loading) -->
    <loading :loading="loadingPhotos || loadingVideos"></loading>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "GalleryMerged",
  data() {
    return {
      // Tab state
      activeTab: 0, // 0: Photo Gallery, 1: Video Visitor
      // Photo gallery state
      loadingPhotos: false,
      photoPosts: [],
      photoModalOpen: false,
      photoIndex: 0,
      // Video visitor state
      loadingVideos: false,
      videoPosts: [],
      videoModalOpen: false,
      videoIndex: 0,
      // Shared state
      baseUrl: process.env.VUE_APP_API_URL+"/storage",
      touchStartY: 0,
      touchEndY: 0,
      language: localStorage.getItem("preferredLanguage") || "en", // Default language
    };
  },
  computed: {
    languageSuffix() {
      return this.language === "id" ? "_id" : "_en";
    },
  },
  created() {
    this.getPhotoPosts();
    this.getVideoPosts();
  },
  methods: {
    // -------------------------
    // Photo Gallery Methods
    // -------------------------
    getPhotoPosts() {
      this.loadingPhotos = true;
      axios
        .get(process.env.VUE_APP_API_URL+"/api/gallery")
        .then((response) => {
          this.loadingPhotos = false;
          // Assuming the API returns images array under items.images
          this.photoPosts = response.data.items.images;
        })
        .catch((error) => {
          this.loadingPhotos = false;
          console.error(error);
        });
    },
    openPhotoModal(index) {
      this.photoIndex = index;
      this.photoModalOpen = true;
    },
    // -------------------------
    // Video Visitor Methods
    // -------------------------
    getVideoPosts() {
      this.loadingVideos = true;
      axios
        .get(process.env.VUE_APP_API_URL+"/api/video-visitor")
        .then((response) => {
          this.loadingVideos = false;
          // Map response items to use language-specific keys if available
          this.videoPosts = response.data.items.map((item) => ({
            thumbnail:
              this.language === "id" ? item.thumbnail_id : item.thumbnail_en,
            title:
              this.language === "id" ? item.title_id : item.title_en,
            video:
              this.language === "id" ? item.video_path_id : item.video_path_en,
          }));
        })
        .catch((error) => {
          this.loadingVideos = false;
          console.error(error);
        });
    },
    openVideoModal(index) {
      this.videoIndex = index;
      this.videoModalOpen = true;
    },
    closeVideoModal() {
      this.videoModalOpen = false;
    },
    getVideoPath(index) {
      if (this.videoPosts && this.videoPosts[index]) {
        return `${this.baseUrl}/${this.videoPosts[index].video}`;
      }
      return "";
    },
    nextVideo() {
      this.videoIndex = (this.videoIndex + 1) % this.videoPosts.length;
    },
    prevVideo() {
      this.videoIndex =
        (this.videoIndex - 1 + this.videoPosts.length) % this.videoPosts.length;
    },
    startTouch(event) {
      this.touchStartY = event.touches[0].clientY;
    },
    onSwipe(event) {
      this.touchEndY = event.touches[0].clientY;
    },
    endTouch() {
      if (this.touchStartY > this.touchEndY + 50) {
        this.nextVideo();
      } else if (this.touchStartY < this.touchEndY - 50) {
        this.prevVideo();
      }
    },
  },
  watch: {
    videoModalOpen(newValue) {
      if (!newValue) {
        const videoPlayer = this.$refs.videoPlayer;
        if (videoPlayer) {
          videoPlayer.pause();
        }
      }
    },
  },
};
</script>

<style scoped>
/* -------------------------
   Photo Gallery Styles
------------------------- */
.v-carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black; /* Ensure the background is black */
}
.v-img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1000;
}

/* -------------------------
   Video Visitor Styles
------------------------- */
.position-relative {
  position: relative;
}
.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  border-radius: 50%;
}
.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}
.video-player {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
.close-btn {
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 999999;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 4px;
}
</style>
