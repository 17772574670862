<template>
  <div style="min-height: 100% !important">
    <v-card
      elevation="4"
      class="rounded custom black--text"
      :to="{
        name: 'activity',
        params: { slug: post.slug, type: post.type.slug },
      }"
      target="_blank"
    >
      <div style="position: relative; width: 100%; height: 100%">
        <!-- Badge -->
        <div
          class="badge"
          v-if="
            post.label_detail || (language === 'id' && post.label_detail_id)
          "
          :style="{
            backgroundColor:
              language === 'id' ? post.label_color_id : post.label_color,
          }"
        >
          {{ post.label_detail }}{{ language === "id" ? "_id" : "" }}
        </div>
        <!-- Image -->
        <v-img
          :src="post.images[0].thumbnail"
          :alt="post.images[0].thumbnail"
          :height="moneyLarge ? '169px' : '111px'"
          width="100%"
          style="border-radius: 9px 9px 0 0"
        ></v-img>
      </div>
      <v-card-text style="min-height: 100% !important">
        <v-layout row>
          <v-flex xs12>
            <div>
              <div
                :class="{ 'one-line': moneyLarge }"
                class="a-title"
                :style="{
                  fontSize: moneyLarge ? '16px' : '12px',
                  marginBottom: moneyLarge ? '10px' : '5px',
                }"
              >
                {{ post.name }}
              </div>
              <div :class="{ 'flex-parent': moneyLarge }">
                <div v-if="!moneyLarge">
                  <i class="mdi mdi-star" style="color: orange"></i>
                  {{ post.rating }} /
                  <span style="color: gray"
                    >({{ post.comment_count || 0 }} reviews)</span
                  >
                </div>
                <div class="flex-custom">
                  <img
                    src="@/assets/images/icons/maps-marker.svg"
                    alt="arrow-right"
                    style="width: 16px; margin-right: 5px"
                  />
                  <p style="color: #000">{{ post.location }}</p>
                </div>
                <div v-if="moneyLarge">
                  <i class="mdi mdi-star" style="color: orange"></i>
                  {{ post.rating }} /
                  <span style="color: gray"
                    >({{ post.comment_count || 0 }} reviews)
                    <!-- {{ post.tag.tag_id }} -->
                  </span>
                </div>
              </div>
            </div>
            <v-divider
              :class="{ 'my-4': moneyLarge, 'my-1': !moneyLarge }"
            ></v-divider>
            <div :class="moneyLarge ? 'flex-parent-custom' : 'flex-parent'">
              <small
                :class="{ caption: !moneyLarge, 'float-right': moneyLarge }"
                style="color: gray"
                >{{ $t("start-from") }}</small
              >
              <span
                v-show="!moneyLarge"
                style="color: #000; font-size: 12px !important"
                class="title"
              >
                <b>
                  {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
                  {{
                    $root.$i18n.locale == "id"
                      ? post.price_idr
                      : post.price_usd | fm
                  }}
                </b>
              </span>
              <span v-show="moneyLarge" class="header-text">
                {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
                {{
                  $root.$i18n.locale == "id"
                    ? post.price_idr
                    : post.price_usd | fm
                }}
              </span>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    post: Object,
    moneyLarge: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      language: localStorage.getItem("preferredLanguage"),
    };
  },
  created() {
    this.language = localStorage.getItem("preferredLanguage");
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0 !important;
}
.flex-custom {
  display: flex;
  align-items: center;
}

.badge {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 9px;
  text-transform: uppercase;
  z-index: 4;
}

.flex-parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.my-2 {
  margin: 8px 0;
}
.rating {
  background-color: #37464d;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}

.rounded {
  border-radius: 9px 9px 9px 9px !important;
}

.caption-large {
  display: inline-block;
  width: max-content;
}

.flex-parent-custom {
  display: flex;
  justify-content: end;
  gap: 10px;
}

.a-title {
  color: black;
  font-weight: 700;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.a-title.one-line {
  -webkit-line-clamp: 1 !important;
}

.card {
  min-height: 100% !important;
  height: 100% !important;
  display: block;
}

.v-card {
  min-height: 100% !important;
}
</style>
