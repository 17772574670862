<template>
  <div>
    <div class="gallery" v-if="photos && photos.length" v-show="!loading">
      <v-container pa-2 fluid>
        <v-row align="center" class="mb-4">
          <v-col cols="8">
            <div class="headline" style="font-weight: 700 !important">
              {{ $t("photo-gallery") }}
            </div>
            <div class="lead">{{ $t("our-happy-customer") }}</div>
            <v-btn
              class="mt-2 custom-btn"
              style="
                background: #fff !important;
                border-radius: 9px !important;
                text-transform: lowercase;
              "
              outlined
              :to="{ name: 'gallery' }"
              small
              roundedoutline
            >
              <img
                src="@/assets/images/icons/gallery-new.svg"
                alt="arrow-right"
                style="width: 20px; height: 20px; margin-right: 5px"
              />
              {{ $t("view-more") }}
            </v-btn>
          </v-col>

          <v-col cols="4" v-if="photos.length > 0">
            <v-img
              class="thumbnail-image"
              :src="photos[0].thumbnail"
              :alt="photos[0].thumbnail"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>

      <!-- VueSlickCarousel -->
      <VueSlickCarousel v-bind="settings" class="gallery-carousel">
        <div
          v-for="(photo, index) in photos"
          :key="index"
          @click="openModal(index)"
        >
          <div class="gallery-item-card">
            <img
              :src="photo.thumbnail"
              :alt="photo.thumbnail"
              class="gallery-image"
            />
          </div>
        </div>
      </VueSlickCarousel>

      <!-- VueSlickCarousel -->
      <VueSlickCarousel v-bind="settingsVideo" class="gallery-carousel2">
        <div
          v-for="(photo, index) in videos"
          :key="index"
          class="gallery-item2"
        >
          <img
            :src="`${baseUrl}/${getThumbnail(photo)}`"
            :alt="photo.thumbnail_en"
            class="gallery-image2"
          />
          <!-- Play Video Icon -->
          <img
            src="@/assets/images/play-video.svg"
            alt="Play Video"
            class="play-video-icon"
            @click="openModal2(index)"
          />
        </div>
      </VueSlickCarousel>
    </div>

    <v-dialog v-model="isModalOpen" fullscreen overlay-color="black">
      <v-card
        class="d-flex flex-column align-center justify-center"
        style="background-color: black !important"
      >
        <!-- Close Button -->
        <v-btn
          class="close-button"
          icon
          color="white"
          @click="isModalOpen = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <!-- Carousel -->
        <v-carousel
          v-model="currentIndex"
          height="100%"
          hide-delimiters
          show-arrows
          class="fill-height"
        >
          <v-carousel-item
            v-for="(photo, i) in photos"
            :key="i"
            class="fill-height"
          >
            <v-img :src="photo.thumbnail" class="fill-height"></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>

    <div
      v-if="isModalOpen2"
      class="video-modal"
      @touchstart="startTouch"
      @touchmove="onSwipe"
      @touchend="endTouch"
    >
      <!-- button close absolute top left-->
      <v-btn
        icon
        class="close-btn"
        @click="isModalOpen2 = false"
        style="
          position: absolute;
          top: 14px;
          right: 14px;
          z-index: 999999;
          background-color: rgba(255, 255, 255, 0.7);
          padding: 4px;
        "
      >
        <v-icon style="font-size: 32px">mdi-close</v-icon>
      </v-btn>
      <video
        controls
        autoplay
        class="video-player"
        ref="videoPlayer"
        :src="`${baseUrl}/${getVideoPath(videos[activeIndex])}`"
      ></video>
    </div>

    <loading :loading="loading"></loading>
  </div>
</template>

<script>
import axios from "axios";
// Import VueSlickCarousel and styles
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

const api = process.env.VUE_APP_API_URL+"/api/gallery";
const apiVideo = process.env.VUE_APP_API_URL+"/api/video-visitor";

export default {
  name: "Gallery",
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      loading: false,
      photos: [], // Gallery photos
      currentIndex: 0, // Track active image index
      isModalOpen: false, // Modal state
      settings: {
        focusOnSelect: true,
        infinite: true,
        rows: 1,
        slidesToShow: 3,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
        dots: false,
      },
      videos: [],
      activeIndex: 0,
      isModalOpen2: false,
      baseUrl: process.env.VUE_APP_API_URL+"/storage",
      settingsVideo: {
        centerMode: true,
        centerPadding: "0px",
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
        dots: false,
      },
      touchStartY: 0,
      touchEndY: 0,
    };
  },
  created() {
    this.getInitialPosts();
    this.getInitialVideo();
  },
  computed: {
    languageSuffix() {
      const preferredLanguage = localStorage.getItem("preferredLanguage");
      return preferredLanguage === "id" ? "_id" : "_en";
    },
  },
  methods: {
    getThumbnail(photo) {
      const thumbnailKey = `thumbnail${this.languageSuffix}`;
      return photo[thumbnailKey] || photo.thumbnail_en;
    },
    getVideoPath(photo) {
      const videoPathKey = `video_path${this.languageSuffix}`;
      return photo[videoPathKey] || photo.video_path_en;
    },
    getInitialVideo() {
      this.loading = true;
      axios
        .get(apiVideo)
        .then((response) => {
          this.loading = false;
          this.videos = response.data.items;
        })
        .catch((e) => {
          this.loading = false;
          console.error(e);
        });
    },
    openModal2(index) {
      this.activeIndex = index;
      this.isModalOpen2 = true;
    },
    nextVideo() {
      this.activeIndex = (this.activeIndex + 1) % this.videos.length;
    },
    prevVideo() {
      this.activeIndex =
        (this.activeIndex - 1 + this.videos.length) % this.videos.length;
    },
    startTouch(event) {
      this.touchStartY = event.touches[0].clientY;
    },
    onSwipe(event) {
      this.touchEndY = event.touches[0].clientY;
    },
    endTouch() {
      if (this.touchStartY > this.touchEndY + 50) {
        this.nextVideo();
      } else if (this.touchStartY < this.touchEndY - 50) {
        this.prevVideo();
      }
    },

    getInitialPosts() {
      this.loading = true;
      axios
        .get(process.env.VUE_APP_API_URL+"/api/gallery")
        .then((response) => {
          this.loading = false;
          this.photos = response.data.items.images.slice(0, 16);
        })
        .catch((e) => {
          this.loading = false;
          console.error(e);
        });
    },
    openModal(index) {
      this.currentIndex = index; // Set active slide
      this.isModalOpen = true;
    },
  },
  watch: {
    isModalOpen2(newValue) {
      if (!newValue) {
        const videoPlayer = this.$refs.videoPlayer;
        if (videoPlayer) {
          videoPlayer.pause();
        }
      }
    },
  },
};
</script>

<style scoped>
.headline {
  font-size: 25px !important;
  font-weight: 400 !important;
}

.lead {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.custom-btn {
  font-size: 16px !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.gallery {
  padding: 30px 8px;
  border-radius: 8px;
  position: relative;
  width: 100vw;
  left: -12px;
  background-color: #F6F6F6;
  background-size: cover;
}

.gallery-carousel {
  margin-top: 24px;
}

.gallery-item-card {
  transition: all 0.3s ease;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.thumbnail-image {
  width: 100%;
  height: 105px;
  border-radius: 20px;
}

.gallery-item-card img {
  width: calc(100% - 5px);
  height: 115px;
  border-radius: 8px;
  object-fit: cover;
}

.gallery .slick-slider .slick-list {
  margin: 0 10px !important;
}

.gallery-image {
  border-radius: 8px;
}

.gallery .slick-list .slick-track {
  display: flex !important;
  gap: 10px !important;
  align-items: center !important;
}

.fill-height {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 10;
}


.gallery-carousel2 {
  margin-top: 14px;
}

.gallery-item2-card {
  margin: 20px 15px; /* Space between items */
  transition: all 0.3s ease;
  height: 198px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 2;
}

/* Ensure gallery items have correct positioning */
.gallery-item2 {
  position: relative;
  transition: all 0.3s ease;
  height: 198px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-image2 {
  border-radius: 20px;
  width: calc(100% - 10px);
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}

.slick-active .gallery-item2-card {
  width: 26vw;
  margin: 0 5px;
  transition: all 0.3s ease;
}

.thumbnail-image {
  border-radius: 25px;
  width: 108px;
  height: 102px;
}

.gallery-item2-card img {
  width: calc(100% - 10px);
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  transition: all 0.3s ease;
}

.slick-slide.slick-center .gallery-item2-card img {
  height: 228px;
  width: calc(34vw - 10px);
  transition: all 0.3s ease;
}

.gallery .slick-slider .slick-list {
  /* overflow-y:visible !important; */
  overflow: visible !important;
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.video-player {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
</style>
