<template>
  <v-app :class="mainBgClass">
    <div class="sexy_preload">
      <img :src="require('@/assets/logo-orange.svg')" alt="">
    </div>

    <v-navigation-drawer persistent :mini-variant="miniVariant" :clipped="clipped" v-model="drawer"
      enable-resize-watcher fixed app style="z-index: 15 !important; min-height: 100vh !important
      ">
      <v-flex pa-0 class="nav-hero">
        <img :src="require('@/assets/images/nav-hero.jpg')" height="auto" width="100%" :alt="title" /><br />
        <div v-if="!isUser">
          <button class="orange-btn" @click="login">LOGIN/REGISTER</button>
        </div>
        <div v-else>
          <button class="orange-btn" @click="logout">LOGOUT</button>
        </div>
      </v-flex>
      <v-list>
        <v-list-item v-for="(item, i) in items" :key="i" :to="item.route" v-on:click="handleRoute(item)">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar dense clipped-left dark color="white" v-show="$route.name !== 'activities' ? true : false"
      style="position: fixed; z-index: 16; top: 0; width: 100%" :class="{ 'no-shadow': isAtTop }">
      <v-app-bar-title>
        <router-link to="/">
          <img src="@/assets/logo-orange.svg" class="toolbar-img justify-center" :alt="title" />
        </router-link>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn style="padding: 0 !important" icon @click.stop="translatedialog = true">
        <img src="@/assets/images/translate-black.svg" class="translate-icon" alt="translate Icon" />
        <div style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            font-size: 10px;
            margin-left: 5px;
            margin-right: 30px;
            font-weight: 400;
            color: #000;
            text-transform: capitalize;
          ">
          <!-- show current code language and the language(indonesia / inggirs)  -->
          <span style="text-transform: uppercase">{{ lang }}</span>
          <span>{{ lang === "en" ? "English" : "Bahasa" }}</span>
        </div>
      </v-btn>
      <v-btn style="padding: 0 !important" icon v-scroll-to="'#searchbar'" to="results">
        <img src="@/assets/images/search-new.svg" class="search-icon" alt="Search Icon" />
      </v-btn>

      <img src="@/assets/images/hamburger-new.svg" alt="nav" @click.stop="drawer = !drawer" class="hamburger" />
      <v-dialog v-model="translatedialog" transition="dialog-transition">
        <v-card>
          <v-card-title>{{ $t("change-language") }}</v-card-title>
          <v-card-text>
            <v-radio-group v-model="lang">
              <v-radio :label="$t('english')" value="en">{{
                $t("english")
              }}</v-radio>
              <v-radio :label="$t('indonesia')" value="id">{{
                $t("indonesia")
              }}</v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="reloadPage">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>

    <!-- activities -->
    <v-app-bar dense clipped-left dark color="transparent" v-show="$route.name !== 'activities' ? false : true"
      style="position: fixed; z-index: 16; top: 0; width: 100%" :class="{ 'no-shadow': isAtTop }">
      <v-app-bar-title>
        <div class="back-btn" @click="closeThisTab">
          <v-btn icon class="back-btn" style="
              background-color: white;
              padding: 4px;
              color: #000 !important;
              max-width: 30px;
              max-height: 30px;
              font-size: 16px !important;
              border-radius: 10px !important;
            ">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </div>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <div class="search-container-navbar">
        <div class="search-input-navbar">
          <img src="@/assets/images/icons/search-black.svg" class="search-icon" alt="Search Icon" />
          <input type="text" v-model="searchQuery" :placeholder="$t('search_new')" v-on:keyup.enter="toSearch" />
        </div>
      </div>
      <v-spacer></v-spacer>

      <v-btn style="padding: 0 !important" icon @click.stop="translatedialog = true">
        <img src="@/assets/images/translate-white.svg" class="translate-icon" alt="translate Icon" />
      </v-btn>

      <img src="@/assets/images/hamburger-new-white.svg" alt="nav" @click.stop="drawer = !drawer" class="hamburger" />
      <v-dialog v-model="translatedialog" transition="dialog-transition">
        <v-card>
          <v-card-title>{{ $t("change-language") }}</v-card-title>
          <v-card-text>
            <v-radio-group v-model="lang">
              <v-radio :label="$t('english')" value="en">{{
                $t("english")
              }}</v-radio>
              <v-radio :label="$t('indonesia')" value="id">{{
                $t("indonesia")
              }}</v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="reloadPage">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>

    <v-main style="padding-top: 48px; background-color:white">
      <div id="searchbar"></div>
      <router-view />
    </v-main>
    <div style="
        display: block;
        width: 100%;
        height: 10px;
        background-color: #073951;
        margin-bottom: 8px;
      "></div>
    <v-footer color="darken-4 white--text text-center" style="
        background-color: #073951 !important;
        padding-top: 20px !important  ;
      ">
      <v-btn dark fab fixed bottom right color="primary" href="https://wa.me/6285857082012"
        v-show="$route.name !== 'activity' ? true : false">
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>

      <v-layout justify-center row wrap>
        <v-flex xs12 pa-4>
          <img src="@/assets/images/logo_white.png" height="40px" width="auto" alt="balitripcenter" /><br />
          <span>{{
            $t(
              "welcome_to_Indonesia_with_thousand_of_beautiful_island_and_culture"
            )
          }} </span><br />
        </v-flex>
        <v-layout row align-center>
          <v-flex xs6>
            <a href="https://www.instagram.com/dewaputu_btc" style="
                display: flex;
                justify-content: center;
                gap: 10px;
                color: white !important;
                text-decoration: none;
              " target="_blank">
              <img src="@/assets/images/icons/instagram.svg" height="30px" alt="follow us" />
              <div style="
                  display: flex;
                  justify-content: flex-start;
                  align-items: start;
                  flex-direction: column;
                ">
                <span class="small">{{ $t("follow_us_on") }}</span>
                <span class="small">@dewaputu_btc</span>
              </div>
            </a>
          </v-flex>
          <v-flex xs6>
            <img src="@/assets/images/icons/wonderful_Indonesia.svg" height="60px" alt="follow us" />
          </v-flex>
        </v-layout>
        <v-flex xs12 py-2>
          <v-divider dark></v-divider>
        </v-flex>
        <v-flex py-3 text-center white--text xs12 style="font-size: 12px !important; font-weight: 400 !important">
          &#169; Bali Trip Center 2018-{{ new Date().getFullYear() }} - All
          Rights Reserved
        </v-flex>
      </v-layout>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      langs: ["id", "en"],
      translatedialog: false,
      isAtTop: true,
      search: false,
      searchQuery: null,
      query: null,
      links: [
        { route: "howtobook", title: this.$t("how-to-book") },
        { route: "term", title: this.$t("term-payment") },
        { route: "cancelation", title: this.$t("cancelation-policy") },
      ],
      clipped: false,
      drawer: null,
      fixed: false,
      guest_items: [
        {
          icon: "mdi-bank",
          title: this.$t("home"),
          route: "/",
        },
        {
          icon: "mdi-developer-board",
          title: this.$t("activities"),
          route: "/activity",
        },
        {
          icon: "mdi-map",
          title: this.$t("destination"),
          route: "/destination",
        },
        {
          icon: "mdi-car",
          title: this.$t("transport"),
          route: "/transport",
        },
        {
          icon: "mdi-image",
          title: this.$t("gallery"),
          route: "/gallery",
        },
        {
          icon: "mdi-book-marker",
          title: this.$t("tips"),
          route: "/tips",
        },
        {
          icon: "mdi-calendar",
          title: this.$t("event"),
          route: "/event",
        },
        {
          icon: "mdi-cart",
          title: this.$t("special-offer-l"),
          route: "/specialoffer",
        },
        {
          icon: "mdi-help-circle-outline",
          title: this.$t("help-and-faq"),
          route: "/helpandfaq",
        },
        {
          icon: "mdi-information",
          title: this.$t("about"),
          route: "/aboutus",
        },
        {
          icon: "mdi-account",
          title: this.$t("login"),
          route: "/login",
        },
        {
          icon: "mdi-account",
          title: this.$t("register"),
          route: "/register",
        },
      ],
      user_items: [
        {
          icon: "mdi-bank",
          title: this.$t("home"),
          route: "/",
        },
        {
          icon: "mdi-developer-board",
          title: this.$t("activities"),
          route: "/activity",
        },
        {
          icon: "mdi-map",
          title: this.$t("destination"),
          route: "/destination",
        },
        {
          icon: "mdi-car",
          title: this.$t("transport"),
          route: "/transport",
        },
        {
          icon: "mdi-image",
          title: this.$t("gallery"),
          route: "/gallery",
        },
        {
          icon: "mdi-book-marker",
          title: this.$t("tips"),
          route: "/tips",
        },
        {
          icon: "mdi-calendar",
          title: this.$t("event"),
          route: "/event",
        },
        {
          icon: "mdi-cart",
          title: this.$t("special-offer-l"),
          route: "/specialoffer",
        },
        {
          icon: "mdi-help-circle-outline",
          title: this.$t("help-and-faq"),
          route: "/helpandfaq",
        },
        {
          icon: "mdi-information",
          title: this.$t("about"),
          route: "/aboutus",
        },
        {
          icon: "mdi-account",
          title: this.$t("profile"),
          route: "/profile",
        },
        {
          icon: "mdi-launch",
          title: this.$t("logout"),
        },
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "Balitripcenter.com",
    };
  },
  methods: {
    closeThisTab() {
      window.close();
    },
    toSearch() {
      this.$router.push({
        name: "results",
        params: { initialQuery: this.searchQuery },
      });
    },
    reloadPage() {
      this.translatedialog = false;
      this.$router.go();
    },
    handleScroll() {
      this.isAtTop = window.scrollY === 0;
    },
    handleRoute(item) {
      if (item.title === this.$t("logout")) {
        this.logout();
      } else {
        this.$router.push(item.route);
      }
    },
    logout() {
      this.$store.dispatch("removeToken");
      this.$store.dispatch("removeProfile");
      this.$router.push("/");
    },
    login() {
      this.$router.push("/login");
    },
  },
  computed: {
    mainBgClass() {
      return this.$route.name === "activity" ? "main-bg-no-hidden" : "main-bg";
    },
    lang: {
      get: function () {
        return this.$store.getters.getLanguage;
      },
      set: function (newVal) {
        this.$store.dispatch("setLanguage", {
          language: newVal,
        });
        localStorage.setItem("preferredLanguage", newVal);
      },
    },
    items() {
      return this.isUser ? this.user_items : this.guest_items;
    },
    isUser() {
      return this.$store.getters.isAuthenticated;
    },
  },
  beforeMount() {
    document.querySelector('.sexy_preload').classList.remove('close');
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    document.addEventListener("readystatechange", () => {
      if (document.readyState === "complete") {
        document.querySelector('.sexy_preload')?.classList.add('close');
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  name: "App",
};
</script>

<style>
.sexy_preload {
  position: fixed;
  z-index: 99999999999;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  transition: 0.3s;
}

.sexy_preload.close {
  z-index: -99999999999;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  /* Zoom in */
  100% {
    transform: scale(1);
  }

  /* Zoom out */
}

.sexy_preload img {
  width: 150px;
  animation: zoomInOut 1.5s infinite ease-in-out;
}

.main-bg {
  background-color: #ffffff !important;
  overflow-x: hidden;
}

.no-shadow {
  box-shadow: none !important;
}

.main-bg-no-hidden {
  background-color: #fbf9fa !important;
}

.main-bg .v-application .caption {
  font-family: "Poppins", sans-serif !important;
}

* {
  font-family: "Poppins", sans-serif;
}

/* Custom styles for the search input */
.search-input-navbar {
  position: relative;
  width: 100% !important;
}

.search-container-navbar {
  width: 70% !important;
  padding: 0px 20px
}

.search-input-navbar input {
  width: 100%;
  padding-left: 40px;
  /* Space for the icon */
  padding-right: 20px;
  height: 30px;
  font-size: 14px;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: white;
  padding-top: 0;
  padding-bottom: 0;
}

.search-input-navbar .search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.header-text {
  font-size: 16px;
  font-weight: 700;
  color: #000;
}

.orange--text {
  color: #ff6100 !important;
}

.v-card__text {
  color: #000 !important;
}

.float-right {
  font-size: 12px !important;
}
</style>
<style scoped>
.hamburger {
  height: 24px;
}

.small {
  font-size: 10px;
}

.nav-hero {
  position: relative;
}

.orange-btn {
  background-color: #ffb101;
  border-radius: 18px;
  padding: 8px 20px;
  min-width: 200px;
  position: absolute;
  font-size: 14px;
  font-weight: bold;
  top: 72%;
  left: 10%;
}

.toolbar-img {
  width: auto;
  height: 30px;
  align-content: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.footer-img {
  width: auto;
  height: 35%;
  align-content: center;
  display: flex;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.flink {
  margin: 0 auto;
  padding: 0 4px;
  font-size: 10px;
}
</style>
