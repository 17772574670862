<template>
  <div>
    <!-- Display each package card -->
    <div v-for="(pack, pIndex) in packages" :key="pIndex" :class="pIndex === 0 ? 'package-card active' : 'package-card'"
      :id="`package-card-${pIndex}`" @click="selectPackageCard(`package-card-${pIndex}`)">
      <div style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap:50px
        ">
        <span class="package-title">{{ pack.name }}</span>
        <div class="package-start-from">
          <div style="display: flex; flex-direction: column; align-items: flex-end">
            <span>{{ $t("from") }}</span>
            <span style="font-weight: 700; color: #2e924a; white-space: nowrap;">
                {{
                $root.$i18n.locale === "id"
                  ? " IDR " + Number(pack.products[0].price_idr).toLocaleString("id-ID", { maximumFractionDigits: 0 })
                  : " USD " + Number(pack.products[0].price_usd).toLocaleString("en-US", { maximumFractionDigits: 0 })
                }}
            </span>
          </div>
        </div>
      </div>

      <div class="package-card-actions">
        <button class="btn-secondary" @click.stop="toggleBottomSheet(pIndex)">
          <img :src="require('@/assets/images/chevron-bottom.svg')" width="16" />
          <span style="color: #ff5100">{{ $t("details") }}</span>
        </button>
        <button class="btn-primary" @click.stop="onBookNow(pack)">
          {{ $t("book-now") }}
        </button>
      </div>
    </div>

    <!-- Bottom Sheet Detail -->
    <transition name="slide-up">
      <div v-if="activeIndex !== null" class="bottom-sheet" @click.self="toggleBottomSheet(null)">
        <div class="bottom-sheet-content">
          <!-- Sheet Header -->
          <div class="bottom-sheet-header-wrap">
            <div class="bottom-sheet-header">
              <button class="close-btn" @click.stop="toggleBottomSheet(null)">
                ✕
              </button>
              <span class="sheet-title"></span>
              <button class="close-btn" style="opacity: 0% !important" @click.stop="toggleBottomSheet(null)">
                ✕
              </button>
            </div>
          </div>

          <!-- Package title and chosen date -->
          <div class="sheet-product-title">
            {{ packages[activeIndex].name }}
          </div>

          <div style="
              display: flex;
              gap: 8px;
              flex-wrap: wrap;
              margin-top: 5px;
              font-size: 12px !important;
              margin-bottom: 8px;
            ">
            <div style="
                color: #757575;
                background-color: #f5f5f5;
                padding: 2px 4px;
                border-radius: 5px;
                max-width: max-content;
                display: flex;
                align-items: center;
                gap: 4px;
              ">
              <img :src="require('@/assets/images/location-icons.svg')" width="16" />
              {{ location }}
            </div>
            <div style="
                color: #757575;
                background-color: #f5f5f5;
                padding: 2px 4px;
                border-radius: 5px;
                max-width: max-content;
                display: flex;
                align-items: center;
                gap: 4px;
              ">
              <img :src="require('@/assets/images/clock-icons.svg')" width="16" />
              24 Hours
            </div>
            <div style="
                color: #757575;
                background-color: #f5f5f5;
                padding: 2px 4px;
                border-radius: 5px;
                max-width: max-content;
                display: flex;
                align-items: center;
                gap: 4px;
              ">
              <img :src="require('@/assets/images/cancelation-icons.svg')" width="16" />
              {{ cancelation }} hrs Cancelation
            </div>
            <div style="
                color: #757575;
                background-color: #f5f5f5;
                padding: 2px 4px;
                border-radius: 5px;
                max-width: max-content;
                display: flex;
                align-items: center;
                gap: 4px;
              ">
              <img :src="require('@/assets/images/duration-icons.svg')" width="16" />
              {{ duration }} Hours
            </div>
          </div>

          <div style="font-weight: 700; margin: 20px 0 16px 0">
            {{ $t("reservation") }}
            <span style="color: green">
              {{
                new Date(choosendate).toLocaleDateString($root.$i18n.locale, {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })
              }}
            </span>
          </div>

          <!-- text price detail with div width 100px center bg color and colot text #ff5100 -->
          <div style="
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 4px;
              margin-bottom: 16px;
            ">
            <span style="color: #ff5100">{{ $t("price-detail") }}</span>
            <div style="
                display: block;
                width: 100px;
                text-align: center;
                height: 5px;
                background-color: #ff5100;
                color: #fff;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
              "></div>
          </div>

          <!-- List products with plus/minus controls -->
          <div v-for="(product, i) in packages[activeIndex].products" :key="i" class="sheet-product-item">
            <div>
              <div class="product-name">
                {{
                  $root.$i18n.locale === "id"
                    ? product.name_id
                    : product.name_en
                }}
              </div>
              <div class="product-price">
                {{
                  $root.$i18n.locale === "id"
                    ? "IDR " +
                    (product.price_idr | fm) +
                    " / " +
                    (product.type || "person")
                    : "USD " +
                    (product.price_usd | fm) +
                    " / " +
                    (product.type || "person")
                }}
              </div>
            </div>

            <!-- Plus / Minus controls -->
            <div class="qty-controls">
              <button @click="addItem(product)">
                <img :src="require('@/assets/images/tambah.svg')" width="24" />
              </button>
              <span>{{ product.ordered }}</span>
              <button @click="removeItem(product)">
                <img :src="require('@/assets/images/kurang.svg')" width="24" />
              </button>
            </div>
          </div>

          <div class="desc_label">{{ $t("description") }}</div>

          <div style="font-size:16px; margin-bottom:100px">
            <!-- Optional description and extra details -->
            <div class="sheet-description" v-html="packages[activeIndex].detail"></div>
            <divider></divider>
            <v-flex id="confirmation">
              <div style="display: flex; align-items: center; gap: 5px; margin-bottom:15px">
                <img src="@/assets/images/activity-information.svg" :alt="title" height="24px" />
                <b id="confirmation">{{
                  $t("activity-information")
                }}</b>
              </div>
              <div v-html="confirmation_detail"></div>
            </v-flex>
            <divider></divider>
            <v-flex id="what_to_bring">
              <div style="display: flex; align-items: center; gap: 5px; margin-bottom:15px">
                <img src="@/assets/images/what-to-bring.svg" :alt="title" height="24px" />
                <b id="what_to_bring">{{ $t("what-to-bring") }}</b>
              </div>
              <div v-html="whattobring"></div>
            </v-flex>
            <divider></divider>
            <v-flex id="pickup">
              <div style="display: flex; align-items: center; gap: 5px; margin-bottom:15px">
                <img src="@/assets/images/pickup.svg" :alt="title" height="24px" />
                <b id="pickup">{{ $t("pickup-time") }}</b>
              </div>
              <div v-html="pickup_time"></div>
            </v-flex>
            <divider></divider>
            <v-flex id="how_to_use">
              <div style="display: flex; align-items: center; gap: 5px; margin-bottom:15px">
                <img src="@/assets/images/terms.svg" :alt="title" height="24px" />
                <b id="how_to_use">{{ $t("how-to-use") }}</b>
              </div>
              <div v-html="how_to_use"></div>
            </v-flex>
            <divider></divider>
            <v-flex id="payment">
              <div style="display: flex; align-items: center; gap: 5px; margin-bottom:15px">
                <img src="@/assets/images/payment-new.svg" :alt="title" height="24px" />
                <b id="payment">{{ $t("payment") }}
                  {{ $t("and-cancelation-policy") }}</b>
              </div>
              <div v-html="payment"></div>
            </v-flex>
            <divider></divider>
            <v-flex>
              <v-flex xs12 class="mb-3">
                <v-icon color="orange">mdi-information</v-icon>
                <b class="orange--text text-darken-1 ms-3">{{
                  $t("additional-information")
                  }}</b>
              </v-flex>
              <div v-html="additional"></div>
            </v-flex>
          </div>
          <divider></divider>

          <div style="margin-top: 20px; text-align: center">
            <div style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #000;
                padding: 8px 24px;
                position: fixed;
                bottom: 0;
                left:0;
                border:0px;
                border-top: 1px solid grey;
                background-color: #f6f6f6;
                width: 100%;
              ">
              <div style="
                  display: flex;
                  flex-direction: column;
                  gap: 8px;

                ">
                <span style="font-weight: 400">{{ $t("total-amount") }}</span>
                <span style="font-weight: bold; text-align:left">
                    {{
                    $root.$i18n.locale === "id"
                      ? "IDR " + Number(totalPrice).toLocaleString("id-ID", { style: "currency", currency: "IDR", maximumFractionDigits: 0}).replace("Rp", "").trim()
                      : "USD " + Number(totalPrice).toLocaleString("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 0 }).replace("$", "").trim()
                    }}
                </span>
              </div>
              <button class="btn-primary" style="width:150px" @click="confirmSelection">
                {{ $t("book-now") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import Divider from "../Divider.vue";
export default {
  components: { Divider },
  name: "PriceDetail",
  props: {
    packages: {
      type: Array,
      default: () => [],
    },
    confirmation_detail: String,
    whattobring: String,
    pickup_time: String,
    how_to_use: String,
    payment: String,
    additional: String,
    choosendate: String,
    location: String,
    duration: Number,
    cancelation: Number,
    totalAmount: Number,
  },
  data() {
    return {
      activeIndex: null,
      categories: [],
      // Tetap menggunakan daftar kategori untuk navigasi detail
      categoriesEn: [
        { id: 5, name: "Confirmation Detail", linKHref: "#confirmation" },
        { id: 6, name: "What to Bring", linKHref: "#what_to_bring" },
        { id: 7, name: "Pickup Time", linKHref: "#pickup" },
        { id: 8, name: "How to Use", linKHref: "#how_to_use" },
        { id: 9, name: "Payment and Cancelation Policy", linKHref: "#payment" },
      ],
      categoriesId: [
        { id: 5, name: "Detail Konfirmasi", linKHref: "#confirmation" },
        { id: 6, name: "Apa yang Harus Dibawa", linKHref: "#what_to_bring" },
        { id: 7, name: "Waktu Penjemputan", linKHref: "#pickup" },
        { id: 8, name: "Cara Penggunaan", linKHref: "#how_to_use" },
        {
          id: 9,
          name: "Kebijakan Pembayaran dan Pembatalan",
          linKHref: "#payment",
        },
      ],
    };
  },
  created() {
    // Pastikan setiap produk memiliki property ordered untuk reaktivitas
    this.packages.forEach((pack) => {
      pack.products.forEach((product) => {
        if (product.ordered == null) {
          this.$set(product, "ordered", product.minimal || 0);
        }
      });
    });
    window.addEventListener("scroll", this.detectActiveSection);
  },
  mounted() {
    // Atur bahasa kategori sesuai preferensi
    this.categories =
      localStorage.getItem("preferredLanguage") === "en"
        ? this.categoriesEn
        : this.categoriesId;
    window.addEventListener("scroll", this.detectActiveSection);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.detectActiveSection);
  },
  computed: {
    // Menghitung total berdasarkan produk yang telah dipilih pada paket aktif
    totalPrice() {
      if (this.activeIndex === null) return 0;
      return this.packages[this.activeIndex].products.reduce((acc, product) => {
        // Hanya hitung produk yang dipilih (misal: ordered > 0)
        if (product.ordered > 0) {
          const price =
            this.$root.$i18n.locale === "id"
              ? product.price_idr
              : product.price_usd;
          return acc + (price || 0) * product.ordered;
        }
        return acc;
      }, 0);
    },
    // Kumpulan produk yang telah dipilih dari paket aktif
    selectedProducts() {
      if (this.activeIndex === null) return [];
      return this.packages[this.activeIndex].products.filter(
        (product) => product.ordered > 0
      );
    },
  },
  methods: {
    toggleBottomSheet(index) {
      this.activeIndex = index === null ? null : index;
      // Emit total saat membuka/meng-close sheet (opsional)
      if (this.activeIndex !== null) {
        this.$emit("update-total", this.totalPrice);
      }
    },
    selected(id) {
      this.active = id;
      const category = this.categories.find((cat) => cat.id === id);
      if (category) {
        const element = document.querySelector(category.linKHref);
        if (element) {
          const offset = -160;
          const bodyRect = document.body.getBoundingClientRect().top;
          const elementRect = element.getBoundingClientRect().top;
          const offsetPosition = elementRect - bodyRect + offset;
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      }
    },
    detectActiveSection() {
      let currentActive = null;
      const offset = 200; // disesuaikan untuk tinggi header
      this.categories.forEach((category) => {
        const element = document.querySelector(category.linKHref);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top >= 0 && rect.top <= offset) {
            currentActive = category.id;
          }
        }
      });
      if (currentActive !== null) {
        this.active = currentActive;
      }
    },
    // Fungsi untuk menambah jumlah produk
    addItem(product) {
      product.ordered++;
      this.$emit("update-total");
    },
    removeItem(product) {
      const min = product.minimal || 0;
      if (product.ordered > min) {
        product.ordered--;
        this.$emit("update-total");
      }
    },
    confirmSelection() {
      this.$emit("booking-now");
    },
    onBookNow(pack) {
      const index = this.packages.indexOf(pack);
      this.toggleBottomSheet(index);
    },
    selectPackageCard(id) {
      const cards = document.querySelectorAll('.package-card');
      cards.forEach(card => card.classList.remove('active'));
      const selectedCard = document.getElementById(id);
      if (selectedCard) {
        selectedCard.classList.add('active');
      }
    }
  },
  filters: {
    fm(value) {
      if (!value) return "";
      return Number(value).toLocaleString();
    },
  },
};
</script>

<style scoped>
.package-card {
  border-radius: 8px;
  margin-bottom: 16px;
  background: url("~@/assets/images/background-price-new.svg") no-repeat center center;
  background-size: 110%;
  padding: 16px;
  border: 1px solid #212121;
}

.package-card.active {
  border: 1px solid #ff6100 !important;
}

.sheet-product-title {
  border-radius: 8px;
  margin-bottom: 16px;
  background: url("~@/assets/images/background-price-new.svg") no-repeat center center;
  background-size: 105%;
  padding: 20px;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}

.package-title {
  font-size: 16px;
  font-weight: bold;
}

.package-card-actions {
  display: flex;
  gap: 8px;
  margin-top: 8px;
  justify-content: space-between;
  align-items: center;
}

.btn-primary {
  background-color: #ff6100;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-secondary {
  background-color: transparent;
  color: #303135;
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.3s ease;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
}

.bottom-sheet {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  max-height: 95vh;
  overflow-y: auto;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
  z-index: 9999;
}

.bottom-sheet-content {
  padding: 16px;
}

.bottom-sheet-header-wrap {
  padding-top: 20px;
  background-color: white;
  position: sticky;
  top: 0px;
  margin-top: -20px
}

.bottom-sheet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.sheet-title {
  display: block;
  border-radius: 20px;
  background-color: #d9d9d9;
  height: 7px;
  width: 113px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.sheet-product-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px dashed #ccc;
}

.product-name {
  flex: 1;
}

.product-price {
  margin-right: 12px;
  font-weight: bold;
}

.qty-controls {
  display: flex;
  align-items: center;
  gap: 8px;
}

.qty-controls button {
  background: none;
  color: #444;
  width: 32px;
  height: 32px;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qty-controls span {
  display: inline-block;
  width: 24px;
  text-align: center;
  font-weight: bold;
}

.sheet-description {
  margin-top: 12px;
  font-size: 16px;
  color: #333;

}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  background-color: white;
  padding: 0px 8px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.scrolling-wrapper .card {
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
  margin: 0 8px;
  height: auto;
  min-height: 40px;
  border: 4px solid white;
  padding: 4px 0 !important;
  font-weight: 400;
  flex: 0 0 auto;
  font-size: 14px;
  margin-top: 5px !important;
  scroll-snap-align: center;
}

.scrolling-wrapper .card.active {
  background-color: white;
  border-bottom-color: #ff6100;
  color: #ff6100;
}

.desc_label {
  padding: 10px 15px;
  background-color: #f6f6f6;
  border-radius: 10px;
  font-weight: bold;
  width: 200px;
  margin-top: 30px;
}
</style>
