<template>
  <div class="rounded black--text review-card" :style="{ border: noBorder ? 'none' : '1px solid black' }">
    <!-- Header Section -->
    <div class="d-flex" style="
        justify-content: space-between;
        margin-bottom: 15px;
        width: 100%;
        min-height: 100%;
      ">
      <div class="d-flex" style="gap: 10px">
        <v-img :src="post.user.image" :alt="post.user.image" height="50px" width="50px" class="rounded-circle"
          @click="openModal(index)">
        </v-img>
        <div>
          <h4 style="color: #212121">
            {{ post.user.name.slice(0, 3) + "***" }}
          </h4>
          <div style="display: flex; gap: 2px; align-items: center" v-if="!newLayout">
            <img src="@/assets/images/stars-new.svg" alt="star" style="width: 15px; height: 15px" />
            <b>{{ post.rating }}<small style="color: #71747d">/5</small></b>
          </div>
          <div style="display: flex; gap: 2px; align-items: center" v-if="newLayout">
            <span v-for="n in post.rating" :key="n">
              <img src="@/assets/images/stars-new.svg" alt="star" style="width: 15px; height: 15px" />
            </span>
          </div>
        </div>
      </div>

      <div>
        <small style="color: #000">{{ post.updated_at | showDate }}</small>
      </div>
    </div>

    <!-- Truncated commentable.name Section -->
    <div style="margin-bottom: 10px">
      <b class="name-comment" style="
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        ">
        {{ post.commentable.name }}
      </b>
    </div>

    <!-- Body and Images Section -->
    <div class="body-images-container2" v-if="!isAllReview && !newLayout">
      <!-- Comment Body -->
      <div :class="post.images && post.images.length > 0
          ? 'body-with-images'
          : 'body-full'
        ">
        <b class="name-comment" style="margin-bottom: 0 !important">
          {{ post.body.slice(0, 54) }}
          <span v-if="post.body.length > 54">...</span>
        </b>
      </div>

      <!-- Images Section -->
      <div class="images-section" v-if="post.images && post.images.length > 0">
        <div class="images-wrapper">
          <div v-for="(image, i) in limitedImages" :key="i" class="image-card">
            <v-img :src="image.thumbnail" :alt="image.thumbnail" height="54px"
              style="border-radius: 7px; cursor: pointer" width="54px" @click="openModal(i)"></v-img>
          </div>
        </div>
      </div>
    </div>

    <div class="body-images-container2" v-if="isAllReview && !newLayout">
      <!-- Comment Body -->
      <div :class="!showMore[index] && post.images && post.images.length > 0
          ? 'body-with-images'
          : 'body-full'
        ">
        <span v-if="!showMore[index]" class="name-comment" style="margin-bottom: 0 !important">
          {{ post.body.slice(0, 54) }}
          <span v-if="post.body.length > 54">...</span>
        </span>
        <span v-else class="name-comment">
          {{ post.body }}
        </span>
        <button @click="toggleShowMore(index)" v-show="(post.body.length > 54 && !showMore[index]) ||
          (post.body.length <= 54 &&
            post.images.length > 0 &&
            !showMore[index])
          " style="
            background: none;
            color: #ff711a;
            border: none;
            cursor: pointer;
            font-size: 12px;
          ">
          {{ showMore[index] ? `${$t("show-less")}` : `${$t("show-more")}` }}
        </button>

        <button @click="toggleShowMore(index)" v-if="showMore[index] && post.images.length === 0" style="
            background: none;
            color: #000;
            border: none;
            cursor: pointer;
            font-size: 14px;
            font-weight: 700;
          ">
          {{ showMore[index] ? `${$t("show-less")}` : `${$t("show-more")}` }}
        </button>
      </div>

      <!-- Images Section -->
      <div class="images-section" v-if="post.images && post.images.length > 0 && !showMore[index]">
        <div class="images-wrapper">
          <div v-for="(image, i) in limitedImages" :key="i" class="image-card">
            <v-img :src="image.thumbnail" :alt="image.thumbnail" height="54px" style="border-radius: 7px" width="54px"
              @click="openModal(index)">
            </v-img>
          </div>
        </div>
      </div>

      <div style="
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;
        " v-if="post.images && post.images.length > 0 && showMore[index]">
        <div>
          <div v-for="(image, i) in post.images" :key="i">
            <img :src="image.thumbnail" :alt="image.thumbnail" style="border-radius: 7px; width: 100%; height: 100%"
              @click="openModal(index)" />
          </div>
        </div>

        <button @click="toggleShowMore(index)" style="
            background: none;
            color: #000;
            border: none;
            cursor: pointer;
            font-size: 14px;
            font-weight: 700;
          ">
          {{ showMore[index] ? `${$t("show-less")}` : `${$t("show-more")}` }}
        </button>
      </div>
    </div>

    <div class="body-images-container2" v-if="isAllReview && newLayout">
      <!-- Comment Body -->
      <div :class="!showMore[index] && post.images && post.images.length > 0
          ? 'body-with-images'
          : 'body-full'
        ">
        <span v-if="!showMore[index]" class="name-comment" style="margin-bottom: 0 !important">
          {{ post.body.slice(0, 54) }}
          <span v-if="post.body.length > 54">...</span>
        </span>
        <p v-else class="name-comment">
          {{ post.body }}
        </p>
        <button @click="toggleShowMore(index)" v-show="(post.body.length > 54 && !showMore[index]) ||
          (post.body.length <= 54 &&
            post.images.length > 0 &&
            !showMore[index])
          " style="
            background: none;
            color: #ff711a;
            border: none;
            cursor: pointer;
            font-size: 12px;
          ">
          {{ showMore[index] ? `${$t("show-less")}` : `${$t("show-more")}` }}
        </button>

        <button @click="toggleShowMore(index)" v-if="showMore[index] && post.images.length === 0" style="
            background: none;
            color: #000;
            border: none;
            cursor: pointer;
            font-size: 14px;
            font-weight: 700;
          ">
          {{ showMore[index] ? `${$t("show-less")}` : `${$t("show-more")}` }}
        </button>
      </div>

      <!-- Images Section -->
      <div class="images-section" v-if="post.images && post.images.length > 0 && !showMore[index]">
        <div class="images-wrapper">
          <div v-for="(image, i) in post.images.slice(0, 5)" :key="i" class="image-card">
            <v-img :src="image.thumbnail" :alt="image.thumbnail" height="54px" style="border-radius: 7px" width="54px"
              @click="openModal(index)">
            </v-img>
          </div>
          <div v-if="post.images.length > 5" class="image-card">
            <v-img :src="post.images[4].thumbnail" :alt="post.images[4].thumbnail" height="54px"
              style="border-radius: 7px; position: relative" width="54px" @click="openModal(index)">
              <div style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(0, 0, 0, 0.5);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: white;
                  font-size: 16px;
                  font-weight: 400;
                  border-radius: 7px;
                ">
                +{{ post.images.length - 5 }}
              </div>
            </v-img>
          </div>
        </div>
      </div>

      <div style="
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;
        " v-if="post.images && post.images.length > 0 && showMore[index]">
        <div>
          <div v-for="(image, i) in post.images" :key="i">
            <img :src="image.thumbnail" :alt="image.thumbnail" style="border-radius: 7px; width: 100%; height: 100%"
              @click="openModal(index)" />
          </div>
        </div>

        <button @click="toggleShowMore(index)" style="
            background: none;
            color: #000;
            border: none;
            cursor: pointer;
            font-size: 14px;
            font-weight: 700;
          ">
          {{ showMore[index] ? `${$t("show-less")}` : `${$t("show-more")}` }}
        </button>
      </div>
    </div>

    <!-- Modal Dialog with Carousel -->
    <v-dialog v-model="isModalOpen" fullscreen overlay-color="black">
      <v-card class="d-flex flex-column align-center justify-center" style="background-color: black !important">
        <!-- Close Button -->
        <v-btn class="close-button" icon color="white" @click="isModalOpen = false"
          style="position: absolute; z-index: 9999; top: 14px; left: 14px">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-carousel v-model="currentIndex" height="100%" hide-delimiters show-arrows class="fill-height">
          <v-carousel-item v-for="(image, i) in post.images" :key="i" :src="image.thumbnail">
            <v-img :src="image.thumbnail" class="fill-height"></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    post: Object,
    isAllReview: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    index: Number,
    newLayout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMore: [],
      isModalOpen: false,
      activeImageIndex: 0,
    };
  },
  computed: {
    limitedImages() {
      return this.post.images.slice(0, 1);
    },
  },
  methods: {
    toggleShowMore(index) {
      this.$set(this.showMore, index, !this.showMore[index]);
    },
    openModal(index) {
      this.activeImageIndex = index;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
  mounted() {
    this.showMore = Array(this.$parent.posts.length).fill(false);
  },
};
</script>

<style scoped>
.review-card {
  padding: 10px;
  border-radius: 7px !important;
  min-height: 100%;
  background-color: #F6F6F6;
}

/* Truncated text style for commentable.name */
b {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.name-comment {
  font-size: 16px !important;
}

/* Body and Images Container */
.body-images-container {
  display: flex;
  flex-wrap: wrap;
}

/* Body and Images Container */
.body-images-container2 {
  display: block;
}

.body-images-container2 .images-section {
  justify-content: flex-start !important;
  margin-top: 10px;
}

.body-images-container2 .images-section .images-wrapper {
  gap: 10px;
}

.body-images-container2 .images-section .image-card {
  margin: 0;
}

.body-with-images {
  flex: 0 0 66.66%;
}

.body-full {
  flex: 0 0 100%;
}

.images-section {
  flex: 0 0 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.images-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-card {
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
