<template>
  <div style="margin-bottom: 20px">
    <div v-if="!loading">
      <div class="hero-text" style="font-size: 16px">
        {{ $t("global-recent") }}
      </div>
      <div v-if="items && items.length" style="margin-top: 20px">
        <v-container fluid grid-list-md pa-0>
          <v-layout row wrap>
            <div class="scrolling-wrapper">
              <v-flex
                v-for="(post, i) of items"
                :key="i"
                style="margin-bottom: 10px"
              >
                <router-link
                  :to="{
                    name: 'activity',
                    params: { slug: post.slug, type: post.type.slug },
                  }"
                  target="_blank"
                  style="text-decoration: none; color: inherit"
                  class="card-custom2"
                >
                  <v-img
                    class="card-img-custom"
                    :src="post.images.first"
                  ></v-img>
                  <div>
                    <h4
                      style="
                        font-size: 14px;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                      "
                    >
                      {{ post.name }}
                    </h4>

                    <!-- add rating post.rating and icon star yellow -->
                    <div class="h-inline" style="font-size: 14px">
                      <v-icon color="orange" small>mdi-star</v-icon>
                      <span style="font-size: 12px; margin-left: 4px">
                        {{ post.comment_count || 0 }}
                      </span>
                    </div>

                    <div class="h-inline">
                      <v-icon color="red" small outlined>mdi-map-marker</v-icon>
                      <span
                        style="
                          font-size: 12px;
                          margin-left: 4px;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          display: -webkit-box;
                          -webkit-line-clamp: 1;
                          -webkit-box-orient: vertical;
                        "
                      >
                        {{
                          post.location.length > 15
                            ? post.location.substring(0, 15) + "..."
                            : post.location
                        }}
                      </span>
                    </div>
                    <div>
                      <small style="color: gray; font-size: 10px !important">{{
                        $t("start-from")
                      }}</small>
                      <span
                        style="color: #000; font-size: 12px !important"
                        class="title"
                      >
                        {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
                        {{
                          $root.$i18n.locale == "id"
                            ? post.price_idr
                            : post.price_usd | fm
                        }}
                      </span>
                    </div>
                  </div>
                </router-link>
              </v-flex>
            </div>
          </v-layout>
        </v-container>
      </div>
    </div>
    <loading :loading="loading"></loading>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      items: [],
      errors: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(`${process.env.VUE_APP_API_URL}/api/activity/recent/global`)
        .then((response) => {
          this.loading = false;
          this.items = response.data.items;
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
.hero-title {
  color: #000;
  position: relative;
  margin: auto;
  padding: auto;
  vertical-align: middle;
  font-weight: 400;
  font-size: 14px;
}

.link-caps {
  color: #000;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
}

.hero-title img {
  margin: 4px 12px 4px 0px;
  width: 30px;
  height: auto;
}

.hero-text {
  font-size: 15px;
  font-weight: 600;
}
.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap; /* Ensure items don't wrap to the next line */
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Prevent vertical scrolling */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling for mobile */
  scroll-snap-type: x mandatory; /* Snap scrolling horizontally */
  width: 100%;
}
.yellow-bar {
  height: 4px;
  background-color: #ff6100;
  width: 10%;
}
.white-bar {
  height: 4px;
  background-color: #ddd;
  width: 40%;
  margin: 0 6px;
}

.h-inline {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.card-custom2 {
  height: 132px;
  width: 295px;
  scroll-snap-align: center;
  flex: 0 0 auto;
  margin-right: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 10px;
  padding: 12.5px !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #d9d9d9;
}

.card-img-custom {
  border-radius: 10px;
  min-width: 123px;
  min-height: 107px;
  width: 123px;
  max-width: 123px;
  height: 107px;
  object-fit: cover;
}
</style>
