<template>
  <div class="reviewbox">
    <div v-if="!loading">
      <div class="review-total-score d-flex">
        <div style="width: 100%">
          <h3
            style="
              border-left: 3px solid #000;
              padding-left: 10px;
              font-size: 16px !important;
            "
          >
            {{ $t("Customer reviews") }}
          </h3>
          <br />
          <h1>
            {{
              overview.avg % 1 === 0
                ? overview.avg + ".0"
                : overview.avg.toFixed(1)
            }}
            <small style="color: #71747d !important">/5</small>
          </h1>
        </div>
        <div class="text-right" style="min-width: max-content">
          <v-btn
            outlined
            elevation="0"
            color="dark"
            style="
              padding: 0 6px;
              text-transform: capitalize;
              border-radius: 7px;
            "
            :to="`/activity-reviews/${type}/${slug}`"
          >
            {{ isAllReviews ? $t("Back to slider") : $t("All reviews") }}
          </v-btn>
          <br /><br />
          <h3 style="line-height: 10px">{{ $t("Review") }}</h3>
          <small style="color: #757575"
            >{{ $t("_from") }} {{ overview.total }}
            {{ $t("Customer reviews") }}</small
          >
        </div>
      </div>

      <div class="divider"></div>

      <div v-if="posts && posts.length">
        <v-container fluid grid-list-md pa-1>
          <v-layout
            :class="{
              'row wrap': !isAllReviews,
              'scrolling-wrapper': isAllReviews,
            }"
          >
            <v-flex
              v-for="(post, i) in posts.slice(0, 3)"
              :key="i"
              :xs12="isAllReviews"
              :sm6="isAllReviews"
              :xl4="isAllReviews"
              class="card"
            >
              <activity-review-card
                :post="post"
                :index="i"
                :noBorder="true"
                :isAllReview="!isAllReviews"
              ></activity-review-card>
            </v-flex>
          </v-layout>
        </v-container>

        <router-link
          :to="`/activity-reviews/${type}/${slug}`"
          class="mt-2"
          v-if="!isAllReviews"
        >
          <v-btn
            outlined
            elevation="0"
            color="dark"
            style="
              padding: 0 6px;
              text-transform: capitalize;
              border-radius: 7px;
              width: 100%;
              margin-top: 10px;
            "
          >
            {{ $t("watch-all-reviews") }}
          </v-btn>
        </router-link>
      </div>
      <div class="divider"></div>

      <loading :loading="loading"></loading>
    </div>

    <comment-dialog
      :dialog="commentDialog"
      :url="url"
      :post="activity"
      @cancelDialog="commentDialog = false"
      @commentSuccess="commentSuccess"
    ></comment-dialog>
    <login-dialog
      :dialog="loginDialog"
      @loginSuccess="loginSuccess"
      @cancel="loginDialog = false"
    ></login-dialog>
  </div>
</template>

<script>
export default {
  props: ["dialog", "url", "posts", "slug", "type", "activity", "overview"],
  data() {
    return {
      loading: false,
      items: [],
      stats: [],
      errors: [],
      commentDialog: false,
      loginDialog: false,
      isAllReviews: false,
    };
  },
  created() {},
  methods: {
    isUser() {
      if (this.$store.getters.isAuthenticated) {
        this.commentDialog = true;
      } else {
        this.loginDialog = true;
      }
    },
    loginSuccess() {
      this.loginDialog = false;
      this.commentDialog = true;
    },
    commentSuccess() {
      this.commentDialog = false;
      this.fetchData();
    },
    toggleLayout() {
      this.isAllReviews = !this.isAllReviews; // Toggle layout between slider and grid-column
    },
  },
};
</script>

<style scoped>
.subheader {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
}

.custom-btn2- {
  font-size: 16px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 9px;
  background-color: #ff6100;
  color: white;
  max-width: 274px;
  width: 274px;
  display: block;
  margin: 20px auto 0 auto;
  font-weight: 700;
}

.custom-btn3- {
  font-size: 16px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 9px;
  background-color: #ff6100;
  width: 100%;
  max-width: 100%;
  color: white;
  display: block;
  margin: 20px auto 20px auto;
  font-weight: 700;
}

.header {
  font-size: 25px;
  font-weight: 700;
}

.review-section-title {
  padding: 15px;
  padding-bottom: 20px;
  padding-top: 20px;
  background-color: #e5f3fe;
  position: absolute;
  top: -10px;
  left: -14px;
  width: calc(100% + 28px);
  background: url("~@/assets/images/home-review-new.png") no-repeat bottom
    center;
  background-size: cover;
  margin-bottom: 30px;
}

.review-total-score-box {
  justify-content: space-between;
  margin-bottom: 10px;
}

.scrolling-wrapper {
  display: flex;
  margin: 0 30px 0 14px;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 30px;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

.scrolling-wrapper .card {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  height: auto;
  flex: 0 0 auto;
  scroll-snap-align: center;
  margin-left: 8px;
}

.row.wrap .card {
  width: 100%;
  /* Full width for grid layout */
  margin-bottom: 16px;
}

.yellow-bar {
  height: 4px;
  background-color: #ff6100;
  width: 10%;
}

.white-bar {
  height: 4px;
  background-color: #ddd;
  width: 40%;
  margin: 0 6px;
}

.h-inline {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.divider {
  width: 110%;
  height: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -20px;
  background-color: #f6f6f6 !important;
}
</style>
