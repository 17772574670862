<template>
  <div class="bg-rental">
    <center>
      <img src="@/assets/images/rental-kendaraan.png" height="auto" width="90%" alt="transport" />
      <div class="text-subtitle-1" style="
          background-color: #dcf6f9 !important;
          color: #000 !important;
          padding: 8px 16px;
          font-weight: 600;
          max-width: max-content;
          border-radius: 9px;
          margin: 16px 0;
        ">
        {{ $t("Bali Transport") }}
      </div>
      <div style="color: #000; padding: 0 10px; font-size: 16px">
        {{ $t("mau-mengexplorasi-bali-tanpa") }}
      </div>
      <br />
    </center>
    <div v-if="!loading">
      <div v-if="items && items.length">
        <v-container fluid grid-list-md pa-1>
          <v-layout row wrap>
            <div class="scrolling-wrapper">
              <v-flex v-for="(post, i) of items" :key="i" xs12 sm6 xl4>
                <router-link :to="{
                  name: 'activity',
                  params: { slug: post.slug, type: post.type.slug },
                }" target="_blank">
                  <div class="box-x rounded elevation-4">
                    <v-img class="box-img" :src="post.images[0].thumbnail" :alt="post.images[0].thumbnail"
                      height="200px" width="100%">
                    </v-img>
                    <div class="box-body">
                      <div class="center-title">{{ post.name }}</div>
                      <div class="price_n_rate">
                        <div style="display:flex; gap:4px;align-items: center;">
                          <p style="margin-bottom: 0px;">
                            {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
                          </p>
                          <span>
                            {{
                              $root.$i18n.locale == "id"
                                ? post.price_idr
                                : post.price_usd
                            }}
                          </span>
                        </div>
                        <div style="color: #000;font-size:14px;">
                          <i class="mdi mdi-star" style="color: orange"></i>
                          {{ post.rating }} / ({{ post.comment_count || 0 }})
                        </div>
                      </div>
                      <div class="btn-orange">{{ $t("pesan-sekarang") }}</div>
                    </div>
                  </div>
                </router-link>
              </v-flex>
            </div>
          </v-layout>
        </v-container>
      </div>
      <center v-else>{{ $t("tidak-ada-transport") }}</center>
      <!-- <center class="mt-2">
        <v-btn to="activity" outlined rounded elevation="0" color="#8BC0CC"
          >EXPLORE MORE</v-btn
        >
      </center> -->
    </div>
    <loading :loading="loading"></loading>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      items: [],
      errors: [],
      category: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(`${process.env.VUE_APP_API_URL}/api/type/15`)
        .then((response) => {
          this.loading = false;
          this.items = response.data.items.data;
          this.category = response.data.category;
          //console.log(response.data.category)

        })
        .catch((e) => {
          this.loading = false;
          // this.errors.push(e)
        });
    },
  },
};
</script>

<style scoped>
.hero-title {
  color: #1ecba2;
  position: relative;
  margin: auto;
  padding: auto;
  vertical-align: middle;
}

.bg-rental {
  position: relative;
  width: 100vw;
  left: -12px;
}

.hero-title img {
  margin: 4px 12px 4px 0px;
  width: 30px;
  height: auto;
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 13%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.hero-icon {
  width: 40px;
  height: auto;
}

.hero-subtext {
  font-size: 16px;
  color: #212121;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

.scrolling-wrapper .box-x {
  display: inline-block;
  width: 254px;
  max-width: 100%;
  height: auto;
  flex: 0 0 auto;
  scroll-snap-align: center;
  margin-left: 8px;
}

.h-inline {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.box-img {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.box-x .box-body {
  background: #fff;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 0 0 10px 10px;
}

.center-title {
  font-weight: 600;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.btn-orange {
  display: block;
  background-color: white;
  border: 2px solid #fd6301;
  max-width: 100%;
  width: 100%;
  font-weight: 700 !important;
  padding: 8px;
  border-radius: 8px;
  color: #fd6301;
  text-align: center;
}

.price_n_rate {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
