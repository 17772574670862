<template>
  <div>
    <!-- Image Grid -->
    <div v-if="posts && posts.length" v-show="!loading">
      <v-container pa-2 fluid grid-list-md>
        <v-layout row wrap>
          <v-flex v-for="(image, i) in posts" :key="i" xs6 sm3>
            <div class="black--text position-relative" @click="openModal(i)">
              <v-img
                :src="`${image.thumbnail}`"
                :alt="image.title"
                height="250px"
                width="100%"
              ></v-img>
              <!-- Play Video Icon -->
              <img
                src="@/assets/images/play-video.svg"
                alt="Play Video"
                class="play-icon"
              />
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <!-- Video Modal -->
    <div
      v-if="isModalOpen"
      class="video-modal"
      @touchstart="startTouch"
      @touchmove="onSwipe"
      @touchend="endTouch"
    >
      <!-- Close Button -->
      <v-btn
        icon
        class="close-btn"
        @click="closeModal"
        style="
          position: absolute;
          top: 14px;
          right: 14px;
          z-index: 999999;
          background-color: rgba(255, 255, 255, 0.7);
          padding: 4px;
        "
      >
        <v-icon style="font-size: 32px">mdi-close</v-icon>
      </v-btn>
      <video
        controls
        autoplay
        class="video-player"
        ref="videoPlayer"
        :src="getVideoPath(activeIndex)"
        controlsList="nodownload"
      ></video>
    </div>

    <!-- Loading Spinner -->
    <loading :loading="loading"></loading>
  </div>
</template>

<script>
import axios from "axios";

const api = process.env.VUE_APP_API_URL+"/api/video-balitrip";

export default {
  data() {
    return {
      loading: false,
      posts: [],
      errors: [],
      isModalOpen: false,
      activeIndex: 0,
      touchStartY: 0,
      touchEndY: 0,
      language: localStorage.getItem("preferredLanguage") || "en", // Default to 'en'
    };
  },
  created() {
    this.getInitialPosts();
  },
  methods: {
    getInitialPosts() {
      this.loading = true;
      axios
        .get(api)
        .then((response) => {
          this.loading = false;
          // Map the API response based on the preferred language
          this.posts = response.data.items.map((item) => ({
            thumbnail: this.language === "id" ? item.thumbnail : item.thumbnail,
            title: this.language === "id" ? item.title : item.title,
            video: this.language === "id" ? item.video_path : item.video_path,
          }));
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
    openModal(index) {
      this.activeIndex = index;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    getVideoPath(index) {
      if (this.posts && this.posts[index]) {
        return `${this.posts[index].video}`;
      }
      return "";
    },
    nextVideo() {
      this.activeIndex = (this.activeIndex + 1) % this.posts.length;
    },
    prevVideo() {
      this.activeIndex =
        (this.activeIndex - 1 + this.posts.length) % this.posts.length;
    },
    startTouch(event) {
      this.touchStartY = event.touches[0].clientY;
    },
    onSwipe(event) {
      this.touchEndY = event.touches[0].clientY;
    },
    endTouch() {
      if (this.touchStartY > this.touchEndY + 50) {
        this.nextVideo();
      } else if (this.touchStartY < this.touchEndY - 50) {
        this.prevVideo();
      }
    },
  },

  watch: {
    isModalOpen(newValue) {
      if (!newValue) {
        const videoPlayer = this.$refs.videoPlayer;
        if (videoPlayer) {
          videoPlayer.pause();
        }
      }
    },
  },
};
</script>

<style scoped>
/* Custom styles for video grid */
.position-relative {
  position: relative;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  border-radius: 50%;
}

.v-card {
  overflow: hidden;
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.video-player {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
</style>
