<template>
  <v-card class="" elevation="" max-width="">
    <v-card-text>
      <v-row >
        <v-col cols="3" class="left">
          <img src="@/assets/images/icons/Lock.svg" alt="dock" class="box" />
        </v-col>
        <v-col cols="9" class="gap-4">
          <div class="caps">{{ $t("masuk-akun-sekarang") }}</div>
          <div class="small-cap">
            {{
              $t("nikmati-mudahnya-melakukan-pemesanan-hanya-satu-kall-daftar")
            }}
          </div>
          <v-btn to="login" block small elevation="0" class="orange--text">{{
            $t("masuk-daftar")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>

<style scoped>
.small-cap{
  font-size: 12px; color: #aaa; text-transform:lowercase;
}

.caps {
  font-size: 12px;
  font-weight: 700;
}

.gap-4 {
  padding-left: 28px;
}

.small-cap::first-letter {
  text-transform: capitalize;
}
.box {
  padding: 24px;
  background-color: #D9D9D9;
  border-radius: 8px;
  height: 100%;
}

.orange--text {
  color: #FF6100 !important;
  background: #D9D9D9 !important;
}

</style>
