<template>
  <a
    :href="'https://m.balitripcenter.com/activity/' + post.slug"
    target="_blank"
    style="min-height: 100%;"
  >
    <v-card class="rounded" elevation="4" style="min-height:100%;">
      <v-container fluid grid-list-lg pa-0 ma-2>
        <v-layout row>
          <v-flex xs5 pa-0>
            <v-img
              :src="post.images[0].thumbnail"
              :alt="post.images[0].thumbnail"
              height="100%"
              width="100%"
            >
            </v-img>
          </v-flex>
          <v-flex xs7 pl-3 pa-2>
            <div>
              <b>{{ post.name }}</b><br />
              <v-flex d-inline-flex pa-0 my-2 mr-2>
                <span class="rating">{{ $t('post-', [post.rating]) }}</span>
                <v-rating
                  v-model="post.rating"
                  background-color="gray"
                  color="yellow accent-4"
                  dense
                  size="12"
                ></v-rating> </v-flex
              >{{ post.comments_count }}<br />
              <p
                ><v-icon size="14">mdi-map-marker</v-icon
                >{{ post.location }}</p
              ><br />
              <p>{{ $t('duration-post-duration', [post.duration]) }}</p
              ><br />
              <span class="cancel"
                ><v-icon dark size="10">mdi-shield-check</v-icon> {{ $t('free-cancelation-post-cancelation', [post.cancelation]) }}}</span
              >
              <v-layout row wrap pa-2>
                <v-flex xs7 pa-0> </v-flex>
                <v-flex xs4 class="rigth" pa-0 mr-1>
                  <span class="gray--text">{{ $t('from') }}</span><br />
                  <div>
                    <p class="top black--text">
                      {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}</p
                    >
                    <span class="title black--text">
                      {{
                        $root.$i18n.locale == "id"
                          ? post.price_idr
                          : post.price_usd | fm
                      }}</span
                    >
                  </div>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </a>
</template>
<script>
export default {
  props: ["post"],
};
</script>
<style scoped>
.rating {
  background-color: #37464d;
  color: white;
  padding: 0 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}

.cancel {
  color: white;
  font-size: 8px;
  background-color: #739a47;
  padding: 4px 6px;
  margin: 4px 0;
  border-radius: 2px;
}

.rigth {
  text-align: right !important;
}
.top {
  vertical-align: top;
}

.v-card {
  min-height: 100% !important;
}

a:hover,
a:visited,
a:link,
a:active {
  color: black;
  background-color: transparent;
  text-decoration: none;
}

.rounded {
  border-radius: 14px !important;
}


.card {
  min-height: 100% !important; 
}
</style>
