<template>
  <div>
    <v-card
      class="black--text"
      :to="{
        name: 'destination',
        params: { slug: post.slug, regency: post.regency.slug },
      }"
      target="_blank"
    >
      <v-container fluid grid-list-lg pa-0 ma-2>
        <v-layout row>
          <v-flex xs4 pa-0>
            <v-img
              :src="post.images[0].thumbnail"
              :alt="post.images[0].thumbnail"
              height="100%"
              style="min-height: 200px"
              width="100%"
            >
            </v-img>
          </v-flex>
          <v-flex xs8 pa-2>
            <div>
              <span class="title">{{ post.name }}</span
              ><br />
              <v-flex d-inline-flex pa-0>
                <span class="rating">{{
                  $t("post-rating", [post.rating])
                }}</span>
                <v-rating
                  v-model="post.rating"
                  background-color="gray"
                  color="yellow accent-4"
                  dense
                  size="15"
                ></v-rating> </v-flex
              ><br />
              <p class="caption">{{ post.location }}</p>
              <br />
              <p class="caption">
                {{
                  $t("post-visitor-visitor-or-post-comments_count-review", [
                    post.visitor,
                    post.comments_count,
                  ])
                }}w
              </p>
              <br />
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["post"],
};
</script>
<style scoped>
.rating {
  background-color: #37464d;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}

a:hover,
a:visited,
a:link,
a:active {
  color: black;
  background-color: transparent;
  text-decoration: none;
}
</style>
