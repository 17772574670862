<template>
  <div class="review-container">
    <loading :loading="loading"></loading>
    <div
      style="
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 14px 12px;
        background-color: #f6f6f6;
      "
    >
      <router-link
        :to="{
          name: 'activity',
          params: { type: $route.params.type, slug: $route.params.slug },
        }"
        class="back-btn"
      >
        <v-btn
          icon
          class="back-btn"
          style="
            background-color: white;
            padding: 4px;
            color: #000 !important;
            font-size: 16px !important;
          "
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </router-link>

      <h4
        style="
          font-size: 16px !important;
          font-weight: 600 !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        "
      >
        {{ $root.$i18n.locale === "id" ? "Ulasan" : "" }}
        {{ post.name }}
        {{ $root.$i18n.locale === "en" ? "Review" : "" }}
      </h4>
    </div>
    <v-flex xs12>
      <v-card elevation="0">
        <v-card-text pa-0 style="padding:10px 0 !important;">
          <div>
            <div v-if="comments.length">
              <comment-header :avg="overview.avg"></comment-header>
              <v-btn color="primary" block @click="isUser">{{
                $t("write-review")
              }}</v-btn
              ><br />
              <span class="title">{{ $t("reviews") }}</span>
              <comment-overview :overview="overview"></comment-overview>
              <v-container fluid grid-list-md pa-0>
                <v-layout class="row wrap">
                  <v-flex
                    v-for="(post, i) in comments"
                    :key="i"
                    :xs12="true"
                    :sm6="true"
                    :xl4="false"
                    class="card"
                  >
                    <activity-review-card
                      :post="post"
                      :isAllReview="true"
                      :index="i"
                      :newLayout="true"
                      :noBorder="true"
                    ></activity-review-card>

                    
                  </v-flex>
                </v-layout>
              </v-container>
              <loading :loading="commentsloading"></loading>
              <div
                class="text-center"
                v-if="!commentsloading && commentsUrl != null"
              >
                <v-btn
                  class="black--text"
                  outlined
                  color="deep-orange"
                  @click="getComments"
                  >{{
                    $t("read-more-reviews-remindcomments", [remindComments])
                  }}</v-btn
                >
              </div>
            </div>
            <div v-else>
              <v-btn color="primary" block @click="isUser">{{
                $t("write-review")
              }}</v-btn
              ><br />
              <v-flex xs12 text-center mt-2>
                <img
                  src="@/assets/images/no_comment.jpg"
                  height="auto"
                  width="100%"
                  alt="Contact Us"
                /><br />
              </v-flex>
            </div>
          </div>
          <comment-dialog
            :dialog="commentDialog"
            :url="url"
            @cancelDialog="commentDialog = false"
            @commentSuccess="commentSuccess"
          ></comment-dialog>
          <login-dialog
            :dialog="loginDialog"
            @loginSuccess="loginSuccess"
            @cancel="loginDialog = false"
          ></login-dialog>
        </v-card-text>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import axios from "axios";

export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      loading: false,
      active: 1,
      commentsloading: false,
      post: null,
      isVisible: false,
      type: null,
      terjemah: [],
      others: [],
      errors: [],
      commentsUrl: null,
      comments: [],
      totalComments: null,
      commentDialog: false,
      loginDialog: false,
      overview: null,
      dialog: false,
      bookingSuccessDialog: false,
      bookingdata: null,
      bookingDialog: false,
      confirmDialog: false,
      settings: {
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 2.5,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
        dots: false,
      },
    };
  },
  mounted() {
    this.fetchData();
    // this.getComments()
  },
  watch: {
    $route: "fetchData",
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/activity/${this.$route.params.type}/${this.$route.params.slug}?lang=${this.$root.$i18n.locale}/`
        )
        .then((response) => {
          this.loading = false;
          this.type = response.data.items.type;
          this.post = response.data.items;
          this.terjemah = response.data.terjemah;
          this.others = response.data.others;
        })
        .then((response) => {
          this.commentsUrl = `${process.env.VUE_APP_API_URL}/api/activity/${this.$route.params.type}/${this.$route.params.slug}/comment`;
          this.getComments();
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
    getComments() {
      this.commentsloading = true;
      axios
        .get(this.commentsUrl)
        .then((response) => {
          this.commentsloading = false;
          this.comments = this.comments.concat(response.data.items);
          this.overview = response.data.overview;
          this.commentsUrl = response.data.items.next_page_url;
          this.totalComments = response.data.items.total;
        })
        .catch((e) => {
          this.commentsloading = false;
          this.errors.push(e);
        });
    },
    isUser() {
      if (this.$store.getters.isAuthenticated) {
        this.commentDialog = true;
      } else {
        this.loginDialog = true;
      }
    },
  },
  computed: {
    url() {
      return `${process.env.VUE_APP_API_URL}/api/activity/${this.$route.params.type}/${this.$route.params.slug}/comment`;
    },
    remindComments() {
      return this.totalComments - this.comments.length;
    },
  },
};
</script>

<style scoped>
.header-contanier2 {
  position: fixed !important;
  top: 48px;
  z-index: 10;
  width: 100%;
  background-color: #f6f6f6;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.review-container {
  position: relative;
  padding: 0 12px;
}

.header-contanier2 .scrolling-wrapper .card.active {
  background-color: white;
  border-bottom-color: #ff6100;
  color: #ff6100;
}

.green-text {
  color: #0fac39;
  font-weight: bold;
  font-size: 1.4rem;
  display: block;
}

.email,
.email:hover {
  text-decoration: none;
  color: black;
}
.v-breadcrumbs {
  padding: 8px !important;
  font-size: 12px;
}
.top {
  vertical-align: top;
}
.sticky {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  z-index: 1;
}
.carousel-img {
  width: 100%;
  height: 240px;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}

.success--image {
  height: 100px;
  width: 100%;
  object-fit: scale-down;
  justify-content: center;
  margin-top: 12px;
}

.text--center {
  text-align: center;
}

.center {
  height: 80vh;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-center {
  vertical-align: middle;
}
.h-center {
  text-align: center;
}

.rating {
  background-color: #37464d;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}
.highlight {
  border-color: #ff6100;
  border-width: 1px;
  border-style: solid;
  padding: 4px;
  margin: 8px 0px;
  background-color: #fff;
  border-radius: 20px;
}
.additonal {
  border-color: #ff6100;
  border-width: 2px;
  border-style: solid;
  padding: 18px 8px;
  background-color: #fffde7;
  border-radius: 4px;
}

.additional ul {
  padding-left: 30px !important;
}

.before_text {
  background-image: url("~@/assets/images/before_title.svg");
  background-repeat: no-repeat;
  padding-left: 10px; /* width of the image plus a little extra padding */
  display: inline-flex; /* may not need this, but I've found I do */
  font-size: 16px;
  font-weight: 600;
  background-position: 0% 50%;
  margin-bottom: 8px;
}
div.check--green {
  list-style-image: url("~@/assets/images/check.svg");
}

.bread {
  color: black;
  text-decoration: none;
}

.bread:hover {
  text-decoration: none;
  color: black;
}
.product_id {
  font-weight: bold;
  height: 25px;
  font-size: 12px;
  position: relative;
}

.vertical-center {
  margin: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.vertical-center img {
  width: 20px;
  height: 20px;
  margin: 0;
  position: absolute;
  top: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.vertical-center .text {
  width: 100%;
  height: 20px;
  margin: 0;
  left: 25px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.activity-title {
  margin-top: 8px;
  font-size: 20px;
  font-weight: bold;
  color: black;
}
</style>
