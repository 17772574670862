<template>
  <div>
    <v-container grid-list-md text-center class="category-card">
      <v-layout row wrap>
        <v-flex xs3>
          <v-card
            class="main-card"
            :to="{ path: '/activity', query: { id: 15 } }"
            target="_blank"
          >
            <v-card-text class="center pa-0">
              <img
                src="@/assets/images/airport-new.png"
                height="auto"
                width="100%"
                alt="Bali Transport"
              /><br />
              <div>
                <div v-html="formatTextToNewline($t('airport-transfer'))"></div>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs3>
          <v-card
            class="main-card"
            :to="{ path: '/activity', query: { id: 3 } }"
            target="_blank"
          >
            <v-card-text class="center pa-0">
              <img
                src="@/assets/images/activity-new.png"
                height="auto"
                width="100%"
                alt="Bali Activity"
              /><br />
              <div v-html="formatTextToNewline($t('bali_activity'))"></div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs3>
          <v-card
            class="main-card"
            :to="{ path: '/activity', query: { id: 5 } }"
            target="_blank"
          >
            <v-card-text class="center pa-0">
              <img
                src="@/assets/images/create-new.png"
                height="auto"
                width="100%"
                alt="Bali Tour"
              /><br />
              <div v-html="formatTextToNewline($t('bali_tour'))"></div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs3>
          <v-card
            class="main-card"
            :to="{ path: '/destination' }"
            target="_blank"
          >
            <v-card-text class="center pa-0">
              <img
                src="@/assets/images/destination-new.png"
                height="auto"
                width="100%"
                alt="Bali Destination"
              /><br />
              <div v-html="formatTextToNewline($t('bali_destination'))"></div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <router-link class="browse-all-btn" to="/activity" target="_blank">
        <img
          src="@/assets/images/icons/category.svg"
          height="24"
          width="24"
          alt="Browse All Category"
          class="mr-2"
        />
        {{ $t("browse_all_category") }}
      </router-link>
    </v-container>
  </div>
</template>
<script>
export default {
  methods: {
    formatTextToNewline(text) {
      return text.split(" ").join("<br />");
    },
  },
};
</script>

<style scoped>
.category-card {
  border-radius: 20px;
  padding: 15px;
  background-color: white;
  box-shadow: 0px 1px 50px 0px rgba(0, 0, 0, 0.25);
  /* position: relative; */
  /* bottom: 80px; */
}
.main-card {
  height: 140px;
  border-radius: 18px !important;
  box-shadow: none !important;
  position: relative;
}

.main-card img {
  width: auto;
  height: 49px;
}

.main-card .center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.browse-all-btn {
  background: #f5f5f5 !important;
  background-color: #f5f5f5 !important;
  padding: 15px;
  border-radius: 15px;
  border: none !important;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}
</style>
