<template>
  <v-card
    class="rounded black--text"
    :to="{ name: 'tip', params: { slug: post.slug } }"
    target="_blank"
  >
    <v-img
      :src="post.images[0].thumbnail"
      :alt="post.images[0].thumbnail"
      height="150px"
      width="100%"
    >
    </v-img>
    <v-card-text>
      <div>
        <div class="black--text">{{ post.title }}</div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ["post"],
};
</script>
<style scoped>
.black--text {
  font-size: 400 !important;
  font-size: 16px;
}
.rating {
  background-color: #37464d;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}

.rounded {
  border-radius: 14px !important;
}
</style>
