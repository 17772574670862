<template>
  <div v-if="tips && tips.length">
    <v-container fluid grid-list-md pa-1>
      <v-layout row wrap>
        <div class="scrolling-wrapper">
        <v-flex v-for="(post, i) of tips" :key="i" xs12 sm6 xl4>
          <home-tips-card class="card" :post="post"></home-tips-card>
        </v-flex>
        </div>
        <v-btn
          to="tips"
          block
          class="mt-2 tips"
          outlined
          color="#000"
          dark
          >{{ $t('see-all') }}</v-btn
        >
      </v-layout>
    </v-container>
  </div>
</template>
<script>
export default {
  props: ["tips"],
};
</script>
<style scoped>

.scrolling-wrapper {
  display: flex;
  margin: 0 -13px;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

.tips {
  font-weight: 700 !important;
  font-size: 14px;
  text-transform: capitalize;
}



.scrolling-wrapper .card {
  display: inline-block;
  width: 260px;
  max-width: 100%;
  height: auto;
  flex: 0 0 auto;
  scroll-snap-align: center;
  margin-left: 8px;
}

img {
  width: 100%;
  height: 200px;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>