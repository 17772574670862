<template>
    <div class="bg-penida">
      <div v-if="!loading">
        <div
          style="
            padding: 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div>
            <div class="body-2 hero-title" style="display: flex !important;align-items:center !important; gap:5px !important;">
              <div class="hero-text">{{ category.name.charAt(0).toUpperCase() + category.name.slice(1).toLowerCase() }}</div>
            </div>
          </div>
           <router-link to="/activity" class="link-caps"  target="_blank">
            {{$t('view_all')}}
            <v-icon>mdi-chevron-right</v-icon>
            </router-link>
        </div>
        <div v-if="items && items.length" style="margin-top: 20px;">
          <v-container fluid grid-list-md pa-1>
            <v-layout row wrap>
              <v-flex v-for="(post, i) of items" :key="i" xs6>
                <home-activitiy-card
                  class="card"
                  :post="post"
                ></home-activitiy-card>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
      </div>
      <loading :loading="loading"></loading>
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        loading: false,
        items: [],
        errors: [],
        category: null,
      };
    },
    created() {
      this.fetchData();
    },
    methods: {
      fetchData() {
        this.loading = true;
        axios
          .get(`${process.env.VUE_APP_API_URL}/api/type/12`)
          .then((response) => {
            this.loading = false;
            this.items = response.data.items.data;
              this.category = response.data.category;
              this.items = this.items.slice(0, 4);
            //console.log(response.data.items.data);
          })
          .catch((e) => {
            this.loading = false;
            this.errors.push(e);
          });
      },
    },
  };
  </script>
  <style scoped>
  .hero-title {
    color: #000;
    font-weight: 700;
    font-size: 16px;
  }
  
  .hero-text {
    top: 50%;
    left: 13%;
    font-size: 16px;
    font-weight: 700;
  }
  
  .link-caps {
  color: #000; text-decoration: none;font-weight: 500;font-size: 14px;
}
  
  .scrolling-wrapper {
    display: flex;
    margin: 0 -13px;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
  }
  
  .scrolling-wrapper .card {
    display: inline-block;
    width: 260px;
    max-width: 100%;
    height: auto;
    flex: 0 0 auto;
    scroll-snap-align: center;
    margin-left: 8px;
  }
  
  .yellow-bar {
    height: 4px;
    background-color: #ff6100;
    width: 10%;
  }
  .white-bar {
    height: 4px;
    background-color: #ddd;
    width: 40%;
    margin: 0 6px;
  }
  
  .h-inline {
    display: inline-flex;
    width: 100%;
    align-items: center;
  }
  
  img {
    width: 100%;
    height: 200px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .bg-penida {
    background: linear-gradient(180deg, #F7F7F7 0%, #dfdfdf 100%);
    color: #000;
    padding: 16px 8px;
    border-radius: 9px;
  }
  </style>
  