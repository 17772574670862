<template>
  <div style="position: relative">
    <div class="header-contanier2" v-show="isVisible">
      <div class="search-main" style="display: flex; gap: 10px; align-items: center">
        <div class="back-btn" @click="closeThisTab">
          <v-btn icon class="back-btn" style="
              background-color: white;
              padding: 4px;
              color: #000 !important;
              font-size: 16px !important;
              box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
            ">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </div>
        <div class="search-container" style="width: 100%">
          <input type="text" :placeholder="$t('find-activities-tours-4g-modem-and-private-transport')
            " v-model="queryInput" />
          <v-btn icon color="black" @click="toSearch" v-on:keyup.enter="toSearch">
            <v-icon style="background-color: transparent !important; margin-left: 10px;">mdi-magnify</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="scrolling-wrapper" style="margin-bottom: 0 !important; margin-top: 10px">
        <div v-for="item in categories" :key="item.name" :id="'item-' + item.id">
          <div @click="selected(item.id)">
            <div v-if="active != item.id">
              <div class="card">
                <div>
                  <img class="type-img" :src="item.images[0].thumbnail" :alt="item.name" />
                  <div>{{ capitalizeFirstWord(item.name) }}</div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="card active">
                <div>
                  <img class="type-img" :src="item.images[0].thumbnail" :alt="item.name" />
                  <div>{{ capitalizeFirstWord(item.name) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="header-new">
      <img src="@/assets/images/banner-activities.png" alt="banner activities"
        style="width: 100%; height: 342px; object-fit: cover" />

      <!-- text section position absolute index 2 -->
      <div style="position: absolute; top: 150px; width: 100%; padding: 0 16px">
        <h2 style="font-size: 18px; font-weight: 700; color: #fff">
          {{ capitalizeFirstWord(dynamicName.toLowerCase()) }}
        </h2>
        <p style="
            font-size: 12px !important;
            font-weight: 400 !important;
            color: #000;
            margin-top: 10px;
            width: max-content;
            background-color: rgba(255, 255, 255, 0.8);
            padding: 4px 16px;
            border-radius: 20px;
          " @click="showDrawer = true">
          {{ $t("read_more_about") }}
          {{ capitalizeFirstWord(dynamicName.toLowerCase()) }}
          <v-icon>mdi-chevron-right</v-icon>
        </p>

        <v-bottom-sheet v-model="showDrawer">
          <v-card>
            <!-- Top section with close icon and gray div -->
            <div class="d-flex align-center justify-space-between px-4 py-2" style="position: relative">
              <v-icon @click="showDrawer = false" class="cursor-pointer">mdi-close</v-icon>
              <div style="
                  width: 50%;
                  height: 5px;
                  position: absolute;
                  left: 50%;
                  transform: translateX(-50%);
                  background-color: gray;
                  border-radius: 10px;
                "></div>
            </div>

            <!-- Content -->
            <v-card-title>{{ dynamicName }}</v-card-title>
            <v-card-text style="overflow-y: scroll">{{
              dynamicDescription
            }}</v-card-text>
          </v-card>
        </v-bottom-sheet>
      </div>
    </div>

    <div class="header-contanier">
      <div class="allcategories">
        <div class="scrolling-wrapper-discover">
          <div v-for="item in categories" :key="item.name" :id="'item-' + item.id">
            <div @click="selected(item.id)">
              <div v-if="active != item.id">
                <div class="card">
                  <img class="type-img" :src="item.images[0].thumbnail" :alt="item.name" />
                  <p style="
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    ">
                    {{ capitalizeFirstWord(item.name) }}
                  </p>
                </div>
              </div>
              <div v-else>
                <div class="card active">
                  <img class="type-img" :src="item.images[0].thumbnail" :alt="item.name" />
                  <p style="
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    ">
                    {{ capitalizeFirstWord(item.name) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading :loading="loading"></loading>

    <v-flex sm12 pa-2 style="margin-top: -160px !important">
      <div v-if="!isPostShow">
        <h4 style="margin-bottom: 10px">{{ $t("you_must_know") }}</h4>
        <home-banner></home-banner>
        <activity-recommendation></activity-recommendation>
        <br />
        <activity-popular></activity-popular>
        <br />
        <activity-transport></activity-transport>
        <br />
        <activity-baliact></activity-baliact>
        <br />
        <activity-balitour></activity-balitour>
        <br />
        <activity-water-sport></activity-water-sport>
        <br />
      </div>
      <div v-if="isPostShow" style="padding-bottom: 30px">
        <div v-if="posts && posts.length">
          <v-container pa-2 fluid grid-list-md>
            <v-layout row wrap>
              <v-flex pa-0 style="margin: 30px 0" v-for="(post, i) of posts" :key="i" xs12 sm6 xl4>
                <activity-card-new :post="post"></activity-card-new>
              </v-flex>
            </v-layout>
          </v-container>
          <div class="text-center" v-if="!loading && currentPage != null">
            <v-btn rounded class="white--text" color="green" @click="getPosts">{{ $t("load-more") }}</v-btn>
          </div>
        </div>
        <v-flex xs12 v-else v-show="!loading" ma-2 text-center>
          <span>{{ $t("this-category-doesnt-have-activity-yet") }}</span>
        </v-flex>
      </div>

      <home-reviews></home-reviews>
      <br />
      <gallery-home></gallery-home>
      <br />
    </v-flex>
    <home-categories :items="items"></home-categories><br />

    <div class="box">
      <v-layout>
        <v-flex xs12 items-center text-center>
          <img src="@/assets/images/icons/livechat-new.svg" height="auto" alt="Contact Us"
            style="margin: auto; width: 84px" /><br />
          <h4 class="text-h4" style="
              font-size: 14px !important;
              text-align: center;
              font-weight: 600;
            ">
            Live Support
          </h4>
          <span class="caption">{{
            $t("any_question_about_balitripcenter")
          }}</span><br />
          <a class="email" href="https://wa.me/6285857082012" target="_blank">
            <span class="caption">+62 85857082012</span> </a><br />
          <a class="email" href="mailto:support@balitripcenter.com">
            <span class="caption">support@balitripcenter.com</span>
          </a>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import ActivityCardNew from "./ActivityCardNew.vue";
const api = process.env.VUE_APP_API_URL+"/api/type";

export default {
  props: ["type"],
  data() {
    return {
      loading: true,
      showMore: false,
      posts: [],
      errors: [],
      itemsCategories: [],
      showDrawer: false,
      currentPage: null,
      isVisible: false,
      valid: true,
      previousPage: this.$router.history.current.query.from || "/",
      email: null,
      isPostShow: false,
      dynamicName: this.$t("discover"),
      dynamicDescription: this.$t("discover_sub"),
      categories: [
        {
          id: null,
          name: this.$t("discover"),
          title: this.$t("all-available-activities-at-our-site"),
          des: this.$t("bali-is-an-island-or-actually"),
          created_at: this.$t("2018-07-09-08-28-51"),
          updated_at: this.$t("2018-07-09-08-28-51"),
          images: [
            {
              thumbnail: require("@/assets/images/icons/discover.svg"),
              original: require("@/assets/images/icons/discover.svg"),
            },
          ],
        },
      ],
      category: null,
      category_name: null,
      category_des: null,
      active: null,
      queryInput: null,
    };
  },
  beforeMount() {
    this.getCategories();
  },
  mounted() {
    this.initializePage();
  },
  created() {
    window.addEventListener("scroll", this.showHeader);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.showHeader);
  },
  watch: {
    category(val, oldVal) {
      if (val !== oldVal) {
        this.scrollToItem('.scrolling-wrapper-discover', val.id);
        if (this.isVisible) {
          this.scrollToItem('.scrolling-wrapper', val.id);
        }
      }
    },
  },
  methods: {
    closeThisTab() {
      window.close();
    },
    async initializePage() {
      const urlParams = new URLSearchParams(window.location.search);
      const activityId = urlParams.get("id");

      if (activityId) {
        this.selected(activityId);
      } else {
        this.selected(null);
      }
    },
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    showHeader() {
      this.isVisible = window.scrollY > 450;
      this.scrollToItem('.scrolling-wrapper', this.category.id);
    },
    capitalizeFirstWord(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    toSearch() {
      this.$router.push({
        name: "results",
        params: { initialQuery: this.queryInput },
      });
    },
    subscribe() {
      if (this.$refs.form.validate()) {
        http.get(`${process.env.VUE_APP_API_URL}/sanctum/csrf-cookie`).then(() => {
          axios
            .post(process.env.VUE_APP_API_URL+"/api/subscriber", {
              email: this.email,
            })
            .then(() => {
              this.email = null;
            })
            .catch(() => { });
        });
      }
    },
    selected(id) {
      this.posts = [];
      this.loading = true;

      if (id == null) {
        // Default behavior for homepage
        this.currentPage = api;
        this.active = null;
        this.isPostShow = false;
        this.dynamicName = this.$t("discover");
        this.dynamicDescription = this.$t("discover_sub");
      } else {
        // Fetch data for the given category
        this.currentPage = `${api}/${id}`;
        this.active = id;
        this.isPostShow = true;
        this.category = this.categories.find((category) => category.id == id);
      }
      this.getPosts();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    getCategories() {
      axios
        .get(process.env.VUE_APP_API_URL+"/api/types")
        .then((response) => {
          this.categories = this.categories.concat(response.data.items);
        })
        .catch(() => { });
    },
    getPosts() {
      axios
        .get(this.currentPage)
        .then((response) => {
          this.loading = false;
          this.posts = this.posts.concat(response.data.items.data);
          this.category = response.data.category;
          this.currentPage = response.data.items.next_page_url;
          if (this.category.name !== "ALL ACTIVITY") {
            this.dynamicName = this.category.name;
            this.dynamicDescription = this.category.des;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async scrollToItem(wrapper, itemId) {
      await this.$nextTick();
      const container = this.$el.querySelector(wrapper);
      const itemElement = this.$el.querySelector(`${wrapper} #item-${itemId}`);

      if (container && itemElement) {
        const containerLeft = container.offsetLeft;
        const itemLeft = itemElement.offsetLeft;

        const offset = 5;

        container.scrollTo({
          left: itemLeft - containerLeft - offset,
          behavior: "smooth",
        });
      }
    }
  },
  components: { ActivityCardNew },
};
</script>

<style scoped>
.header-new {
  position: relative;
  top: -47px;
  width: 100%;
}

.header-contanier {
  position: relative;
  width: 100%;
  background-color: rgb(255, 255, 255);
  z-index: 3;
  padding: 14px 8px 0px 8px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  top: -150px;
}

.header-contanier2 {
  position: fixed !important;
  top: 0;
  z-index: 88;
  width: 100%;
  background-color: #f6f6f6;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 8px 10px;
}

.header-contanier h2 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.header-image {
  height: 100%;
  width: 100%;
}

.search-container {
  position: relative;
  border-radius: 30px;
  border-color: #eee;
}

.search-container input[type="text"] {
  font-size: 14px;
  overflow: hidden;
  border-radius: 30px;
  padding: 8px;
  color: #212121 !important;
  width: 100%;
  height: 45px;
  border: 1px solid #212121;
  text-indent: 30px;
  padding-left: 15px
}

/* placeholder color 212121 */
.search-container input[type="text"]::placeholder {
  color: #212121;
}

.search-container button {
  position: absolute;
  left: 4px;
  padding: 8px;
  font-size: 17px;
  border: none;
  height: 100%;
  color: white;
  cursor: pointer;
  border-radius: 15px;
}

.search-container button:hover {
  background: #ccc;
}

.scrolling-wrapper-discover {
  display: flex;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: none;
  /* Matikan snap */
  overflow-x: auto;
  /* Biar lebih smooth */
  padding-bottom: 10px;
  margin-bottom: 10px;
  background-color: transparent;
}

.scrolling-wrapper-discover .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 88px;
  min-width: 88px;
  background-color: #f6f6f6;
  margin: 0 8px 0 0;
  height: 100%;
  min-height: 93.6px;
  padding: 4px 12px !important;
  font-weight: regular;
  flex: 0 0 auto;
  font-size: 12px;
  text-align: center;
  margin-top: 5px !important;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #212121;
  scroll-snap-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.scrolling-wrapper-discover .card p {
  margin-bottom: 0 !important;
}

.scrolling-wrapper-discover .card.active {
  border-color: #ff6100;
  background-color: #ffeee3;
  padding: 4px 12px !important;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: none;
  /* Matikan snap */
  overflow-x: auto;
  /* Biar lebih smooth */
  padding-bottom: 10px;
  margin-bottom: 10px;
  margin-top: 5px !important
}

.scrolling-wrapper .card {
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
  min-width: 120px;
  background-color: #f6f6f6;
  margin: 0 8px 0 0;
  height: auto;
  min-height: 40px;
  padding: 4px 12px !important;
  flex: 0 0 auto;
  font-size: 14px;
  margin-top: 5px !important;
  border-radius: 30px;
  border-width: 1px;
  border-style: solid;
  border-color: #212121;
  scroll-snap-align: center;
}

.scrolling-wrapper .card div {
  display: flex;
  gap: 10px;
  align-items: center
}

.scrolling-wrapper .card .type-img {
  margin: 0px;
  margin-left: -6px
}

.scrolling-wrapper .card.active {
  border-color: #18191b;
  background-color: #d9d9d9;
  padding: 4px 12px !important;
}

.scrolling-wrapper .card.active div {
  color: #212121;
  font-weight: bold
}

.header-contanier2 .scrolling-wrapper .card.active {
  border-color: #3b3b3b;
  background-color: #ffeee3;
  padding: 4px 12px !important;
}

.type-img {
  width: 40px;
  height: 40px;
  background: #fff;
  overflow: hidden;
  border-radius: 100%;
  object-fit: contain;
  object-position: center;
  margin-top: 10px
}

.clip img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.rating {
  background-color: #37464d;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}

.title {
  color: black;
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 0px !important;
}

.input-group {
  border-radius: 4px;
  margin-top: 12px;
  position: relative;
  height: 40px;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  background: white;
  width: 100%;
}

.button {
  border-radius: 0 4px 4px 0;
  background-color: black;
  padding: 8px 12px;
  position: absolute;
  height: 100%;
  right: 0;
  color: white;
}

.input {
  padding: 8px;
  margin: 0px;
  width: 80%;
  height: 100%;
  left: 0;
  position: absolute;
  background-color: white;
}

.category-title {
  padding: 8px;
  font-size: 18px;
  color: #222;
}

.box {
  background-color: #fff;
  margin: 8px;
  padding: 8px;
  border-radius: 12px;
}

.box img {
  margin-top: 10px;
}

.email,
.email:hover {
  text-decoration: none;
  color: black;
}

.category-title {
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 700;
  color: #222;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.see-all {
  color: #000;
  text-decoration: none !important;
  font-size: 14px;
  font-weight: 600;
}

.see-all:hover {
  text-decoration: none;
}

.see-all v-icon {
  font-size: 16px;
}

.hidden {
  display: none !important;
}

.allcategories .card {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 110px !important
}
</style>
