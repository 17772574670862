<template>
  <div>
    <v-flex xs12 text-center mt-2 pa-0>
      <div class="img_bg">{{ $t('and-layanan') }}</div>
      <span class="text-gray" style="font-size: 13px;"
        >{{ $t('we-offer-many-activities') }}i</span
      >
    </v-flex>
    <v-container fluid grid-list-md pa-1>
      <v-layout row wrap>
        <div class="scrolling-wrapper">
          <v-flex text v-for="(item, i) in items" :key="i" xs12 sm6 xl4>
            <router-link
              :to="{ name: 'activities', params: { type: item.id } }"
            >
              <img class="card" :src="item.image" alt="Balitripcenter" />
            </router-link>
          </v-flex>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
export default {
  /* eslint-disable */
  data() {
    return {
      itemId: null,
      items: [
        {
          image: require("@/assets/images/activity_hero/adventure_xs.png"),
          id: "1",
        },
        {
          image: require("@/assets/images/activity_hero/tour_xs.png"),
          id: "6",
        },
        {
          image: require("@/assets/images/activity_hero/westbali_xs.png"),
          id: "10",
        },
        {
          image: require("@/assets/images/activity_hero/fastboat_xs.png"),
          id: "8",
        },
      ],
    };
  },
};
</script>
<style scoped>
.img_bg {
  background-color: #fd6301;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 8px;
  font-size: 14px;
  width: auto;
  object-position: center;
  color: white;
  display: block;
  margin-bottom: 8px;
}
.text-gray {
  color: rgba(0, 0, 0, 0.54);
}

.scrolling-wrapper {
  display: flex;
  margin: 0 -13px;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

.scrolling-wrapper .card {
  display: inline-block;
  width: 260px;
  height: auto;
  flex: 0 0 auto;
  scroll-snap-align: center;
  margin-left: 8px;
}
</style>