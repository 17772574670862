<template>
  <div>
    <v-container class="feature-header" fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs8>
          <div class="my-title">{{ $t('bali-event-calendar') }}</div>
          <div class="caption black--text" style="line-height:normal">
            {{ $t('bali-event-desc') }}
          </div>
        </v-flex>
        <v-flex xs4>
          <img class="img float-right" src="@/assets/images/wisata.png" />
        </v-flex>
      </v-layout>
    </v-container>
    <div v-if="items && items.length">
      <v-container fluid grid-list-md pa-1>
        <v-layout row wrap>
          <div class="scrolling-wrapper">
            <v-flex v-for="(post, i) of items" :key="i" xs12 sm6 xl4>
              <home-event-card class="card" :post="post"></home-event-card>
            </v-flex>
          </div>
        </v-layout>
      </v-container>
      <!-- <v-flex mt-1 xs12 text-center>
        <v-btn rounded to="/event" color="primary" outlined>{{ $t('explore-more') }}</v-btn>
      </v-flex> -->
    </div>
    <loading :loading="loading"></loading>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      items: [],
      errors: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(`${process.env.VUE_APP_API_URL}/api/event/feature`)
        .then((response) => {
          this.loading = false;
          this.items = response.data.items;
          //console.log(response.data.items);
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
  },
};
</script>
<style scoped>
.feature-header {
  padding: 16px;
  background-image: linear-gradient(to right, #8DDB6D, #3E64FF);
  border-radius: 14px;
  margin: 4px 4px 16px 0px;
  color: white;
}

.my-title {
  color: #ff6100;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 700;
}

.feature-header .img {
  height: 80px;
  width: 100px;
}

.scrolling-wrapper {
  display: flex;
  margin: 0 -13px;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

.scrolling-wrapper .card {
  display: inline-block;
  width: 260px;
  max-width: 100%;
  height: auto;
  flex: 0 0 auto;
  scroll-snap-align: center;
  margin-left: 8px;
}

.yellow-bar {
  height: 4px;
  background-color: #ff6100;
  width: 10%;
}
.white-bar {
  height: 4px;
  background-color: #ddd;
  width: 40%;
  margin: 0 6px;
}

.h-inline {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

img {
  width: 100%;
  height: 200px;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>