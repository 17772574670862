<template>
  <v-layout pa-2 style="gap:10px !important;">
    <v-flex xs6>
      <div>
        <h4 class="box">{{ $t('activities') }}</h4>
        <ul>
          <li v-for="(type, i) in items.types" :key="i">
            <div class="small">{{ type.name }}</div>
          </li>
        </ul>
      </div>
    </v-flex>
    <v-flex xs6>
      <div>
        <h4 class="box">{{ $t('destinations') }}</h4>
        <ul>
          <li v-for="(regency, i) in items.regencies" :key="i">
            <div class="small">{{ regency.name }}</div>
          </li>
        </ul>
      </div>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  props: ["items"],
};
</script>
<style scoped>
.small {
  font-size: 14px;
  padding: 1px 0;
  text-transform: uppercase;
}

.small:first-letter {
  text-transform: uppercase;
}

ul {
  padding-left: 20px !important;
}

li {
  list-style-type: disc;
}

.box {
  background-color: #D9D9D9;
  display: block;
  text-align: center;
  border-radius: 9px !important;
  padding: 4px;
  margin-bottom: 8px;
  color: #333;
}
</style>
