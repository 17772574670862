<template>
  <div id="#activities">
    <div v-if="!loading">
      <div style="padding: 4px 0px; display: flex; justify-content: space-between; align-items: center;">
        <div class="body-2 hero-title" style="flex: 1;">
          <div>
            <img
              class="hero-icon"
              src="@/assets/images/activity-icon.svg"
              alt="english"
            />
            <div class="hero-text" style="margin-left: 5px;font-size: 16px;">
              {{ $t('activities') }}
            </div>
          </div>
        </div>
        
        <router-link to="/activity?id=3" class="link-caps"  target="_blank">
          {{$t('view_all')}}
        </router-link>
      </div>
      <small class="hero-subtext" style="margin: 10px 0;line-height:21px">{{ category.title }}</small>
      <div v-if="items && items.length" style="margin-top: 20px;">
        <v-container fluid grid-list-md pa-1>
          <v-layout row wrap>
            <div class="scrolling-wrapper">
              <v-flex v-for="(post, i) of firstHalfItems" :key="i" xs12 sm6 xl4 style="margin-bottom: 10px; margin-left:-4px;">
                <home-activitiy-card
                  class="card"
                  :post="post"
                  :moneyLarge="true"
                ></home-activitiy-card>
              </v-flex>
            </div>
            <div class="scrolling-wrapper">
              <v-flex v-for="(post, i) of secondHalfItems" :key="i" xs12 sm6 xl4>
                <home-activitiy-card
                  class="card"
                  :post="post"
                  :moneyLarge="true"
                ></home-activitiy-card>
              </v-flex>
            </div>
          </v-layout>
        </v-container>
      </div>
    </div>
    <loading :loading="loading"></loading>  
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      items: [],
      errors: [],
      category: null,
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    firstHalfItems() {
      const half = Math.ceil(this.items.length / 2);
      return this.items.slice(0, half);
    },
    secondHalfItems() {
      const half = Math.ceil(this.items.length / 2);
      return this.items.slice(half);
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(`${process.env.VUE_APP_API_URL}/api/type/3`)
        .then((response) => {
          this.loading = false;
          this.items = response.data.items.data;
          this.category = response.data.category;
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
.hero-title {
  color: #000;
  position: relative;
  margin: auto;
  padding: auto;
  vertical-align: middle;
  font-weight: 400;
  font-size: 14px;
}

.link-caps {
  color: #000; text-decoration: none;font-weight: 500;font-size: 14px;
}

.hero-title img {
  margin: 4px 12px 4px 0px;
  width: 30px;
  height: auto;
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 13%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 15px;
  font-weight: 600;
}

.hero-icon {
  width: 40px;
  height: auto;
}

.hero-subtext {
  font-size: 16px;
  color: #212121;
}

.scrolling-wrapper {
  display: flex;
  margin-left: -12px;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

.scrolling-wrapper .card {
  display: inline-block;
  width: 260px;
  max-width: 100%;
  height: auto;
  flex: 0 0 auto;
  scroll-snap-align: center;
  margin-left: 8px;
}

.yellow-bar {
  height: 4px;
  background-color: #ff6100;
  width: 10%;
}
.white-bar {
  height: 4px;
  background-color: #ddd;
  width: 40%;
  margin: 0 6px;
}

.h-inline {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

img {
  width: 100%;
  height: 200px;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
