<template>
  <div>
    <div class="gallery" v-if="videos && videos.length" v-show="!loading">
      <v-container pa-2 fluid>
        <v-row align="center" class="mb-4">
          <v-col cols="4" v-if="videos.length > 0">
            <v-img
              class="thumbnail-image"
              :src="`${getThumbnail(videos[0])}`"
              :alt="videos[0].thumbnail_en"
            ></v-img>
          </v-col>
          <v-col cols="8">
            <div class="headline" style="font-weight: 700 !important">
              {{ $t("video-gallery") }}
            </div>
            <div class="lead">{{ $t("our-happy-customer") }}</div>
            <v-btn
              class="mt-2 custom-btn"
              outlined
              :to="{ name: 'videoVisitor' }"
              small
              roundedoutline
            >
              <img
                src="@/assets/images/icons/gallery-new.svg"
                alt="arrow-right"
                class="icon"
              />
              {{ $t("view-more") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <!-- VueSlickCarousel -->
      <VueSlickCarousel v-bind="settingsVideo" class="gallery-carousel2">
        <div
          v-for="(photo, index) in videos"
          :key="index"
          class="gallery-item2"
        >
          <img
            :src="`${getThumbnail(photo)}`"
            :alt="photo.thumbnail"
            class="gallery-image2"
          />
          <!-- Play Video Icon -->
          <img
            src="@/assets/images/play-video.svg"
            alt="Play Video"
            class="play-video-icon"
            @click="openModal2(index)"
          />
        </div>
      </VueSlickCarousel>
    </div>

    <div
      v-if="isModalOpen2"
      class="video-modal"
      @touchstart="startTouch"
      @touchmove="onSwipe"
      @touchend="endTouch"
    >
      <!-- button close absolute top left-->
      <v-btn
        icon
        class="close-btn"
        @click="isModalOpen2 = false"
        style="
          position: absolute;
          top: 14px;
          right: 14px;
          z-index: 999999;
          background-color: rgba(255, 255, 255, 0.7);
          padding: 4px;
        "
      >
        <v-icon style="font-size: 32px">mdi-close</v-icon>
      </v-btn>
      <video
        controls
        autoplay
        class="video-player"
        ref="videoPlayer"
        :src="videoBlobUrl"
        controlsList="nodownload"
      ></video>
    </div>

    <loading :loading="loading"></loading>
  </div>
</template>

<script>
import axios from "axios";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

const apiVideo = process.env.VUE_APP_API_URL+"/api/video-visitor";

export default {
  name: "VideoGallery",
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      loading: false,
      videos: [],
      activeIndex: 0,
      isModalOpen2: false,
      videoBlobUrl: "",
      settingsVideo: {
        centerMode: true,
        centerPadding: "0px",
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
        dots: false,
      },
      touchStartY: 0,
      touchEndY: 0,
    };
  },
  computed: {},
  methods: {
    getThumbnail(photo) {
      const thumbnailKey = `thumbnail`;
      return photo[thumbnailKey] || photo.thumbnail;
    },
    getVideoPath(photo) {
      const videoPathKey = `video_path`;
      return photo[videoPathKey] || photo.video_path;
    },
    async fetchVideoStream(index) {
      try {
        const videoPath = `${this.getVideoPath(this.videos[index])}`;
        const response = await fetch(videoPath);
        const blob = await response.blob();
        this.videoBlobUrl = URL.createObjectURL(blob);
      } catch (error) {
        console.error("Error fetching video stream:", error);
      }
    },
    getInitialPosts() {
      this.loading = true;
      axios
        .get(apiVideo)
        .then((response) => {
          this.loading = false;
          this.videos = response.data.items;
        })
        .catch((e) => {
          this.loading = false;
          console.error(e);
        });
    },
    async openModal2(index) {
      this.activeIndex = index;
      await this.fetchVideoStream(index);
      this.isModalOpen2 = true;
    },
    nextVideo() {
      this.activeIndex = (this.activeIndex + 1) % this.videos.length;
      this.fetchVideoStream(this.activeIndex);
    },
    prevVideo() {
      this.activeIndex =
        (this.activeIndex - 1 + this.videos.length) % this.videos.length;
      this.fetchVideoStream(this.activeIndex);
    },
    startTouch(event) {
      this.touchStartY = event.touches[0].clientY;
    },
    onSwipe(event) {
      this.touchEndY = event.touches[0].clientY;
    },
    endTouch() {
      if (this.touchStartY > this.touchEndY + 50) {
        this.nextVideo();
      } else if (this.touchStartY < this.touchEndY - 50) {
        this.prevVideo();
      }
    },
  },
  watch: {
    isModalOpen2(newValue) {
      if (!newValue) {
        const videoPlayer = this.$refs.videoPlayer;
        if (videoPlayer) {
          videoPlayer.pause();
        }
        URL.revokeObjectURL(this.videoBlobUrl);
      }
    },
  },
  created() {
    this.getInitialPosts();
  },
};
</script>

<style scoped>
.headline {
  font-size: 25px !important;
  font-weight: 400 !important;
}

.lead {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.custom-btn {
  font-size: 16px !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.gallery {
  padding: 30px 8px;
  border-radius: 8px;
  position: relative;
  width: 100vw;
  left: -12px;
  background-color: #F6F6F6;
  background-size: cover;
}

.gallery-carousel2 {
  margin-top: 14px;
}

.gallery-item2-card {
  margin: 20px 15px; /* Space between items */
  transition: all 0.3s ease;
  height: 198px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 2;
}

/* Ensure gallery items have correct positioning */
.gallery-item2 {
  position: relative;
  transition: all 0.3s ease;
  height: 198px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-image2 {
  border-radius: 20px;
  width: calc(100% - 10px);
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}

.slick-active .gallery-item2-card {
  width: 26vw;
  margin: 0 5px;
  transition: all 0.3s ease;
}

.thumbnail-image {
  border-radius: 25px;
  width: 108px;
  height: 102px;
}

.gallery-item2-card img {
  width: calc(100% - 10px);
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  transition: all 0.3s ease;
}

.slick-slide.slick-center .gallery-item2-card img {
  height: 228px;
  width: calc(34vw - 10px);
  transition: all 0.3s ease;
}

.gallery .slick-slider .slick-list {
  /* overflow-y:visible !important; */
  overflow: visible !important;
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.video-player {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
/* Custom slick carousel modifications */
.slick-track {
  display: flex !important;
  gap: 20px !important; /* Add 20px gap between slides */
  align-items: center !important; /* Align slides vertically to center */
  transition: all 0.3s ease;
}

.slick-slide {
  display: flex !important;
  margin: 0 5px;
  justify-content: center !important;
  transition: all 0.3s ease;
  align-items: center !important;
  height: 100%; /* Ensure full height for vertical alignment */
}
</style>
